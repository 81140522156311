<template>
  <div id="add">
    <van-form>
      <van-cell-group inset>
        <van-cell @click="showStockSelect">
          <van-field
            autocomplete="off"
            v-model="form.scName"
            name="scName"
            label="仓库"
            required
        /></van-cell>
        <van-cell @click="showSupplierSelect">
          <van-field
            autocomplete="off"
            required
            v-model="form.supplierName"
            name="supplierName"
            label="供应商"
        /></van-cell>
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="form.purchaserName"
            name="purchaserName"
            label="采购员"
        /></van-cell> -->
        <van-cell @click="openDateTimePicker('form', 'expectArriveDate')">
          <van-field
            autocomplete="off"
            required
            v-model="form.expectArriveDate"
            name="expectArriveDate"
            label="预计到货日期"
        /></van-cell>
      </van-cell-group>
      <van-form style="margin: 16px">
        <div class="action">
          <Van-icon
            name="add-o"
            type="primary"
            @click="toShowProductSelect"
          ></Van-icon>
        </div>
        <CustomTable
          :scrollWidth="600"
          :showPagination="false"
          :columns="columns"
          :tableData="tableData"
        ></CustomTable>
      </van-form>

      <div style="margin: 16px">
        <van-form>
          <van-cell>合计</van-cell>
          <van-cell-group>
            <van-cell
              ><van-field
                autocomplete="off"
                label="采购数量"
                readonly
                v-model="form.totalNum"
                type="number"
            /></van-cell>
            <van-cell
              ><van-field
                autocomplete="off"
                label="赠品数量"
                readonly
                type="number"
                v-model="form.giftNum"
            /></van-cell>
            <van-cell
              ><van-field
                autocomplete="off"
                label="含税总金额"
                readonly
                type="number"
                v-model="form.totalAmount"
            /></van-cell>
          </van-cell-group>
        </van-form>
        <!-- 约定支付 -->
        <PayType
          ref="payType"
          type="add"
          style="margin: 10px 0"
          :list="tableData2"
        ></PayType>
        <!-- 备注 -->
        <van-field
          autocomplete="off"
          v-model="form.description"
          disabled
          rows="2"
          autosize
          label="备注"
          type="textarea"
        />
        <van-cell class="actions-btn">
          <van-button type="primary" @click="onAddConfirm" round block
            >保存</van-button
          >
          <van-button
            type="info"
            @click="toDirectApprovePass"
            round
            block
            v-permission="'purchase:order:approve'"
            >直接审批通过</van-button
          >
          <van-button @click="close" type="default" round block
            >关闭</van-button
          >
        </van-cell>
      </div>
    </van-form>
    <ProductSelect
      type="order"
      :scId="this.form.scId"
      :visible="showProductSelect"
      columnsType="checkbox"
      @confirm="onSelectProductConfirm"
    ></ProductSelect>
    <DateTimePicker
      value-format="yyyy-MM-dd"
      :showPicker="showDatePicker"
      @confirm="onDatePickerConfirm"
      @cancel="showDatePicker = false"
    ></DateTimePicker>
    <StockSelect
      :visible="stockSelectVisible"
      @confirm="stockSelectConfirm"
    ></StockSelect>
    <SupplierSelect
      :visible="supplierSelectVisible"
      @confirm="supplierSelectConfirm"
    ></SupplierSelect>
  </div>
</template>

<script>
import { create, directApprovePass } from "../../../service/api/purchase/order";
import SupplierSelect from "../../../components/supplierSelect..vue";
import ProductSelect from "../../../components/productSelect.vue";
import StockSelect from "../../../components/stockSelect.vue";
import CustomTable from "../../../components/customTable.vue";
import DateTimePicker from "../../../components/dateTimePicker.vue";
import PayType from "../../../components/payType.vue";
import { Notify, Toast } from "vant";
import moment from "moment";

export default {
  name: "index",
  components: {
    CustomTable,
    ProductSelect,
    StockSelect,
    SupplierSelect,
    DateTimePicker,
    PayType,
  },
  data() {
    return {
      showDatePicker: false,
      showDatePicker: false,
      stockSelectVisible: false,
      supplierSelectVisible: false,
      showProductSelect: false,
      datePickType: "product",
      form: {},
      tableData: [],
      tableData2: [],
      columns: [
        {
          field: "productName",
          key: "productName",
          title: "商品名称",
          width: 100,
          fixed: "left",
        },
        {
          field: "productCode",
          key: "productCode",
          title: "商品编号",
          width: 80,
        },

        { field: "unit", key: "unit", title: "单位", width: 80 },
        { field: "spec", key: "spec", title: "规格", width: 80 },
        {
          field: "productionDate",
          key: "productionDate",
          title: "生产日期",
          width: 130,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return (
              <van-field
                autocomplete="off"
                name="productionDate"
                value={text}
                on-click={() => this.openDateTimePicker("product", rowIndex)}
              ></van-field>
            );
          },
        },
        {
          field: "shelfLifeDays",
          key: "shelfLifeDays",
          title: "保质期时长（天）",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return (
              <van-field
                autocomplete="off"
                name="shelfLifeDays"
                value={text}
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "categoryName",
          key: "categoryName",
          title: "商品类目",
          width: 80,
        },
        { field: "brandName", key: "brandName", title: "商品品牌", width: 80 },
        {
          field: "purchasePrice",
          key: "purchasePrice",
          title: "采购价（元）",
          align: "right",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return (
              <van-field
                autocomplete="off"
                name="purchasePrice"
                value={text}
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
                type="number"
              ></van-field>
            );
          },
        },
        {
          field: "taxRate",
          key: "taxRate",
          title: "税率（%）",
          align: "right",
          width: 50,
        },
        {
          field: "isGift",
          key: "isGift",
          title: "是否赠品",
          width: 50,
          formatter: ({ cellValue }) => {
            return cellValue ? "是" : "否";
          },
        },
        {
          field: "purchaseNum",
          key: "purchaseNum",
          title: "采购数量",
          align: "right",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return (
              <van-field
                autocomplete="off"
                name="purchaseNum"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "purchaseAmount",
          key: "purchaseAmount",
          title: "采购含税金额",
          align: "right",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return (
              <van-field
                autocomplete="off"
                name="purchaseAmount"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "description",
          key: "description",
          title: "备注",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return (
              <van-field
                autocomplete="off"
                name="description"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "",
          key: "e",
          title: "操作",
          width: "",
          center: "left",
          fixed: "right",
          width: 50,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span class="action">
                <van-icon
                  name="delete-o"
                  on-click={() => this.deleteRow(rowIndex)}
                ></van-icon>
              </span>
            );
          },
        },
      ],
    };
  },
  watch: {
    tableData: {
      handler(newVal, oldVal) {
        this.calcSum();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  methods: {
    toShowProductSelect() {
      if (!this.form.scId) {
        Notify({ type: "warning", message: "请先选择仓库" });
        return;
      }
      this.showProductSelect = true;
    },
    genParams() {
      const params = {
        scId: this.form.scId,
        supplierId: this.form.supplierId,
        purchaserId: this.form.purchaserId,
        expectArriveDate: this.form.expectArriveDate,
        description: this.form.description,
        payTypes: this.$refs.payType.getTableData().map((t) => {
          return {
            id: t.payTypeId,
            payAmount: t.payAmount,
            text: t.text,
          };
        }),
        products: this.tableData.map((t) => {
          return {
            productId: t.productId,
            purchasePrice: t.purchasePrice,
            purchaseNum: t.purchaseNum,
            description: t.description,
            productionDate: t.productionDate,
            shelfLifeDays: t.shelfLifeDays,
            shelfLife: this.addDays(t.productionDate, t.shelfLifeDays),
          };
        }),
      };
      return params;
    },
    addDays(date, days) {
      if (!date || !days) {
        return "";
      }
      const result = new Date(date);
      result.setDate(result.getDate() + Number(days));
      return moment(result).format("YYYY-MM-DD");
    },
    validData() {
      if (this.$utils.isEmpty(this.form.scId)) {
        this.$utils.createError("仓库不允许为空！");
        return false;
      }

      if (this.$utils.isEmpty(this.form.supplierId)) {
        this.$utils.createError("供应商不允许为空！");
        return false;
      }

      if (this.$utils.isEmpty(this.form.expectArriveDate)) {
        this.$utils.createError("预计到货日期不允许为空！");
        return false;
      }

      if (this.$utils.isEmpty(this.tableData)) {
        this.$utils.createError("请录入商品！");
        return false;
      }

      for (let i = 0; i < this.tableData.length; i++) {
        const product = this.tableData[i];

        if (this.$utils.isEmpty(product.productId)) {
          this.$utils.createError("第" + (i + 1) + "行商品不允许为空！");
          return false;
        }

        if (this.$utils.isEmpty(product.purchasePrice)) {
          this.$utils.createError("第" + (i + 1) + "行商品采购价不允许为空！");
          return false;
        }
        // if (this.$utils.isEmpty(product.productionDate)) {
        //   this.$utils.createError('第' + (i + 1) + '行商品生产日期不允许为空！');
        //   return false;
        // }
        // if (this.$utils.isEmpty(product.shelfLifeDays)) {
        //   this.$utils.createError('第' + (i + 1) + '行商品保质期不允许为空！');
        //   return false;
        // }
        if (!this.$utils.isFloat(product.purchasePrice)) {
          this.$utils.createError("第" + (i + 1) + "行商品采购价必须为数字！");
          return false;
        }

        if (product.isGift) {
          if (parseFloat(product.purchasePrice) !== 0) {
            this.$utils.createError("第" + (i + 1) + "行商品采购价必须等于0！");
            return false;
          }
        } else {
          if (!this.$utils.isFloatGtZero(product.purchasePrice)) {
            this.$utils.createError("第" + (i + 1) + "行商品采购价必须大于0！");
            return false;
          }
        }

        if (!this.$utils.isNumberPrecision(product.purchasePrice, 2)) {
          this.$utils.createError(
            "第" + (i + 1) + "行商品采购价最多允许2位小数！"
          );
          return false;
        }

        if (this.$utils.isEmpty(product.purchaseNum)) {
          this.$utils.createError(
            "第" + (i + 1) + "行商品采购数量不允许为空！"
          );
          return false;
        }

        if (!this.$utils.isInteger(product.purchaseNum)) {
          this.$utils.createError(
            "第" + (i + 1) + "行商品采购数量必须为整数！"
          );
          return false;
        }

        if (!this.$utils.isIntegerGtZero(product.purchaseNum)) {
          this.$utils.createError("第" + (i + 1) + "行商品采购数量必须大于0！");
          return false;
        }
      }
      if (!this.$refs.payType.validData()) {
        return false;
      }
      if (!this.validatePayTypeAmount()) {
        this.$utils.createError(
          "所有约定支付的支付金额不等于含税总金额，请检查！"
        );
        return false;
      }

      return true;
    },
    toDirectApprovePass() {
      if (!this.validData()) {
        return;
      }
      const params = this.genParams();
      directApprovePass(params).then((res) => {
        Toast.success(res.msg);
        this.$router.push({ path: "/purchase/order" });
      });
    },
    onAddConfirm() {
      if (!this.validData()) {
        return;
      }
      const params = this.genParams();
      create(params).then((res) => {
        Toast.success(res.msg);
        this.$router.push({ path: "/purchase/order" });
      });
    },
    // 计算汇总数据
    calcSum() {
      let totalNum = 0;
      let giftNum = 0;
      let totalAmount = 0;

      this.tableData
        .filter((t) => {
          return (
            this.$utils.isFloatGeZero(t.purchasePrice) &&
            this.$utils.isIntegerGeZero(t.purchaseNum)
          );
        })
        .forEach((t) => {
          const num = parseInt(t.purchaseNum);
          if (t.isGift) {
            giftNum = this.$utils.add(giftNum, num);
          } else {
            totalNum = this.$utils.add(totalNum, num);
          }
          totalAmount = this.$utils.add(
            totalAmount,
            this.$utils.mul(num, t.purchasePrice)
          );
        });

      this.form.totalNum = totalNum;
      this.form.giftNum = giftNum;
      this.form.totalAmount = totalAmount;
    },
    validatePayTypeAmount() {
      const data = this.$refs && this.$refs.payType.getTableData();

      if (data.length <= 0) {
        return false;
      } else {
        const payTypeAmount = data.reduce((pre, cur) => {
          return pre + Number(cur.payAmount);
        }, 0);

        return payTypeAmount == this.form.totalAmount;
      }
    },
    showStockSelect() {
      this.stockSelectVisible = true;
    },
    stockSelectConfirm(row) {
      if (Object.keys(row).length === 0) {
        this.stockSelectVisible = false;
        return;
      }
      this.stockSelectVisible = false;
      this.form.scName = row.name;
      this.form.scId = row.id;
    },
    showSupplierSelect() {
      this.supplierSelectVisible = true;
    },
    supplierSelectConfirm(row) {
      if (Object.keys(row).length === 0) {
        this.supplierSelectVisible = false;
        return;
      }
      this.supplierSelectVisible = false;
      this.form.supplierName = row.name;
      this.form.supplierId = row.id;
    },
    inputChange(rowIndex, field, val) {
      this.$set(this.tableData, rowIndex, {
        ...this.tableData[rowIndex],
        [field]: val,
      });
    },
    openDateTimePicker(type = "product", index) {
      this.datePickType = type;
      this.index = index;
      this.showDatePicker = true;
    },
    deleteRow(index) {
      this.tableData.splice(index, 1);
    },
    onSelectProductConfirm(row) {
      if (this.showProductSelect) {
        this.showProductSelect = false;
      }

      if (row.length) {
        row.forEach((el) => {
          this.tableData.push({
            ...el,
          });
        });
      }
    },
    onDatePickerConfirm(val) {
      if (!val) return;
      if (this.datePickType == "product") {
        this.$set(this.tableData, this.index, {
          ...this.tableData[this.index],
          productionDate: val,
        });
      } else {
        this.form[this.index] = val;
      }
      this.showDatePicker = false;
      this.index = 0;
    },
    close() {
      this.$router.push({ path: "/purchase/order" });
    },
  },
};
</script>
<style scoped>
.van-cell {
  padding: 5px 0px 5px 17px;
}
#add {
  position: relative;
}
::v-deep .van-field__body {
  height: 100%;
}
.actions-btn {
  .van-button {
    margin: 5px 5px 0 0;
  }
}
.action {
  background-color: #fff;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 26px;
  height: 36px;
}
</style>
