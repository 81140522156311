<template>
  <div>
    <header class="pt10">
      <DateChange
        :title="form.productName"
        ref="dateChange"
        @change="handlerDateChange"
      />
    </header>
    <div class="flex flex-evenly mt10">
      <div class="item flex flex-column flex-center">
        <div class="fs14 grey-7 tac">销售额</div>
        <div class="fs20 grey-8 fb tac">{{ form.totalAmount || 0 }}</div>
      </div>
      <div class="item flex flex-column flex-center">
        <div class="fs14 grey-7 tac">销售单</div>
        <div class="fs20 grey-8 fb tac">{{ form.orderCnt || 0 }}</div>
      </div>
      <div class="item flex flex-column flex-center">
        <div class="fs14 grey-7 tac">销售数</div>
        <div class="fs20 grey-8 fb tac">{{ form.retailCount || 0 }}</div>
      </div>
    </div>
    <div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <CustomTable
          :tableData="tableData"
          :columns="columns"
          :maxHeight="380"
          :scrollWidth="width"
          @rowClick="rowClick"
          :showPagination="false"
      /></van-list>
      <!-- :total="totalCount"
        :pageIndex="pageIndex"
        :pageSize="pageSize"
        @pageChange="pageChange" -->
    </div>
  </div>
</template>
<script>
import DateChange from "@/components/dateChange.vue";
import CustomTable from "../../../components/customTable.vue";
import {
  singleProduct,
  singleProductOrderList,
} from "../../../service/api/home";
export default {
  name: "index",
  components: { DateChange, CustomTable },
  data() {
    return {
      loading: false,
      finished: false,
      name: "",
      id: this.$route.params.id || localStorage.getItem("productId"),
      form: {},
      tableData: [],
      columns: [
        {
          field: "createTime",
          key: "createTime",
          width: 100,
          title: "销售时间",
        },
        {
          field: "price",
          key: "price",
          width: 60,
          title: "销售单价",
        },
        {
          field: "retailCount",
          key: "retailCount",
          width: 60,
          title: "销售量",
        },
        {
          field: "totalAmount",
          key: "totalAmount",
          width: 60,
          title: "销售额",
        },
      ],
      params: {},
      totalCount: "0",
      pageIndex: 1,
      pageSize: 20,
    };
  },
  computed: {
    width() {
      return document.body.clientWidth - 30;
    },
  },
  mounted() {
    this.params.startDate = this.$refs.dateChange.formattedStartDate;
    this.params.endDate = this.$refs.dateChange.formattedEndDate;
    this.id = this.$route.params.id || localStorage.getItem("productId");
    this.getRetail();
    this.getOrderList();
  },
  methods: {
    onLoad() {
      if (
        this.pageIndex !== 0 &&
        (this.pageIndex >= this.totalPage || this.totalCount === 0)
      ) {
        this.finished = true;
        return;
      }
      this.pageIndex++;
      this.getOrderList(true);
    },
    getRetail() {
      const params = {
        ...this.params,
        productId: this.id,
      };
      singleProduct(params).then((res) => {
        if (Object.keys(res.data).length > 0) {
          this.form = res.data;
        }
      });
    },
    getOrderList(flag = false) {
      const params = {
        ...this.params,
        productId: this.id,
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      };
      singleProductOrderList(params).then((res) => {
        this.totalCount = res.data.totalCount;
        this.totalPage = res.data.totalPage;
        this.pageIndex = res.data.pageIndex;
        if (flag) {
          this.tableData.push(...res.data.datas);
          this.loading = false;
        } else {
          this.tableData = res.data.datas;
        }
        if (
          this.pageIndex !== 0 &&
          (this.pageIndex >= this.totalPage || this.totalCount === 0)
        ) {
          this.finished = true;
        }
      });
    },
    pageChange(pageOptions) {
      this.pageIndex = pageOptions.pageIndex;
      this.pageSize = pageOptions.pageSize;
      this.getOrderList();
    },
    handlerDateChange(startDate, endDate) {
      this.params = { ...this.params, startDate, endDate };
      this.pageIndex = 1;
      this.finished = false;
      this.getRetail();
      this.getOrderList();
    },
    rowClick({ row, rowIndex, e }) {
      localStorage.setItem("productId", this.id);
      //进入订单详情页
      this.$router.push({
        path: "/analysis/orderDetail/" + row.orderId,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  width: 30%;
  border-radius: 3px;
  background-color: #fff;
  padding: 10px;
}
.custom-table {
  margin-top: 15px;
  background-color: #fff;
}
</style>
