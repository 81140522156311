<template>
  <div id="myProduct">
    <!--导航栏-->
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      :fixed="true"
      :border="true"
      @click-left="onClickLeft"
    />
    <transition name="router-slider" mode="out-in">
      <router-view style="margin-top: 3rem"></router-view>
    </transition>
  </div>
</template>
<script>
import { watch } from "less";

export default {
  name: "index",
  data() {
    return { title: "商品管理" };
  },
  created() {},
  watch: {
    $route: {
      handler(to, from) {
        this.title = to.meta.title;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onClickLeft() {
      console.log(this.$route);
      localStorage.setItem("scanText", "");
      if (this.$route.path === "/product/category") {
        this.$router.push("/product/productManagement");
        return;
      }
      if (this.$route.path === "/product/add") {
        this.$router.push("/product/productManagement");
        return;
      }
      if (this.$route.path === "/product/productManagement") {
        this.$router.push("/dashboard/home");
        return;
      }
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.van-nav-bar--fixed {
  position: fixed !important;
}
</style>
