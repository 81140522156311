<template>
  <div id="myPurchase">
    <!--导航栏-->
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      :fixed="true"
      :border="true"
      @click-left="onClickLeft"
    />

    <transition name="router-slider" mode="out-in">
      <router-view style="margin-top: 2.5rem"></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  name: "index",
  data() {
    return { title: "商品分析" };
  },
  created() {},
  watch: {
    $route: {
      handler(to, from) {
        this.title = to.meta.title;
        console.log(to, from);
        if (
          to &&
          from &&
          to.path.includes("/analysis/orderList") &&
          from.path.includes("/analysis/product")
        ) {
          const preRoute = { path: from.path, query: from.query };
          console.log(111, from, preRoute, JSON.stringify(preRoute));
          localStorage.setItem("preRoute", JSON.stringify(preRoute));
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onClickLeft() {
      console.log(this.$route);
      if (this.$route.path.includes("/analysis/orderDetail")) {
        this.$router.push(
          "/analysis/orderList/" + localStorage.getItem("productId")
        );
        return;
      }
      if (this.$route.path.includes("/analysis/orderList")) {
        let preRoute = JSON.parse(localStorage.getItem("preRoute") || "");
        if (preRoute) {
          let path = preRoute.path;
          let query = preRoute.query;
          this.$router.push({ path, query });
        } else {
          this.$router.back();
        }
        return;
      }
      if (this.$route.meta.prev) {
        this.$router.replace(this.$route.meta.prev);
        return;
      }
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.van-nav-bar--fixed {
  position: fixed !important;
}
</style>
