<template>
  <div id="addProduct">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          autocomplete="off"
          v-model="formData.name"
          name="name"
          label="商品名称"
          required
        />
        <van-field
          autocomplete="off"
          v-model="formData.shortName"
          name="shortName"
          label="商品简称"
        />
        <van-cell
          title="商品图片"
          autocomplete="off"
          v-model="formData.picture"
        >
          <UploadImage @change="imageChange"
        /></van-cell>
        <!-- @focus="scanQRCode" -->
        <van-field
          autocomplete="off"
          label="外部编号(条码)"
          name="externalCode"
          required
          v-model:value="formData.externalCode"
          allow-clear
        >
          <template #right-icon>
            <van-icon name="scan" @click="toScan"></van-icon>
          </template>
        </van-field>

        <van-cell @click="showDialog">
          <van-field
            autocomplete="off"
            v-model="formData.categoryName"
            name="categoryName"
            label="所属类目名"
            required
          >
          </van-field>
        </van-cell>

        <van-cell @click="showBrandDialog">
          <van-field
            autocomplete="off"
            label="商品品牌"
            name="brandId"
            required
            v-model:value="formData.brandName"
          ></van-field>
        </van-cell>
        <van-field
          autocomplete="off"
          label="规格"
          name="spec"
          v-model:value="formData.spec"
          allow-clear
        >
        </van-field>

        <van-field
          autocomplete="off"
          label="单位"
          name="unit"
          v-model:value="formData.unit"
          allow-clear
        />

        <van-field autocomplete="off" name="radio" label="是否称重商品">
          <template #input>
            <van-radio-group v-model="formData.isWeight" direction="horizontal">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          autocomplete="off"
          v-if="productType === '1'"
          v-model="formData.taxRate"
          name="taxRate"
          required
          type="number"
          label="进项税率（%）"
        />
        <van-field
          autocomplete="off"
          v-if="productType === '1'"
          v-model="formData.saleTaxRate"
          required
          type="number"
          name="saleTaxRate"
          label="销项税率（%）"
        />
        <van-field
          autocomplete="off"
          v-if="productType === '1'"
          v-model="formData.purchasePrice"
          name="purchasePrice"
          required
          type="number"
          label="采购价（元）"
        />
        <van-field
          autocomplete="off"
          v-model="formData.salePrice"
          required
          type="number"
          name="salePrice"
          label="销售价（元）"
        />
        <van-field
          autocomplete="off"
          v-model="formData.retailPrice"
          required
          type="number"
          name="retailPrice"
          label="零售价（元）"
        />
        <van-field
          autocomplete="off"
          label="数量"
          name="productDefaultNum"
          type="number"
          v-model:value="formData.productDefaultNum"
          allow-clear
          placeholder="请输入数字"
        />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
    <CategorySelect
      :visible="categorySelectVisible"
      @confirm="categorySelectConfirm"
    ></CategorySelect>
    <BrandSelect
      :visible="brandSelectVisible"
      @confirm="brandSelectConfirm"
    ></BrandSelect>
    <van-loading color="#fff" vertical class="loadingBox" v-if="loading"
      >正在采购...</van-loading
    >
  </div>
</template>
<script>
import { create } from "../../service/api/product";
import * as api from "../../service/api/index";

import { getCamelCharsUpperCase, cleanedStr } from "@/utils/index";
import { PRODUCTTYPE, AVAILABLE } from "../../utils/constants";
import CategorySelect from "../../components/categorySelect.vue";
import BrandSelect from "../../components/brandSelect.vue";
import { Toast } from "vant";
import UploadImage from "../../components/uploadImage.vue";
import jsQR from "jsqr";
import Qrcode from "../../components/qrcodeScan.vue";

export default {
  name: "index",
  components: {
    CategorySelect,
    BrandSelect,
    UploadImage,
    Qrcode,
  },
  data() {
    return {
      formDataPic: null,
      loading: false,
      brandSelectVisible: false,
      categorySelectVisible: false,
      columns: [
        {
          key: "product",
          title: "单品",
          slots: { default: "product_default" },
        },
        {
          key: "bundle_num",
          title: "包含数量",
          align: "right",
          slots: { default: "bundleNum_default", header: "bundleNum_header" },
        },
        {
          key: "salePrice",
          title: "销售价（元）",
          align: "right",
          slots: { default: "salePrice_default", header: "salePrice_header" },
        },
        {
          key: "retailPrice",
          title: "零售价（元）",
          align: "right",
          slots: {
            default: "retailPrice_default",
            header: "retailPrice_header",
          },
        },
      ],
      productType: "1",
      productTypeList: PRODUCTTYPE,
      id: this.$route.params.id,
      showPicker: false,
      statusList: AVAILABLE,
      formData: {
        productType: "1",
        taxRate: "0",
        saleTaxRate: "0",
        salePrice: "0",
        purchasePrice: "0",
        isWeight: 0,
        available: "1",
        name: "",
        retailPrice: "",
        categoryName: "",
        unit: "",
      },
    };
  },
  watch: {
    "formData.name": {
      handler(newVal, oldVal) {
        this.formData.shortName = cleanedStr(getCamelCharsUpperCase(newVal));
      },
    },
  },
  created() {
    try {
      this.formData.externalCode =
        localStorage.getItem("scanText") || new Date().getTime();
    } catch (error) {
      this.formData.externalCode = new Date().getTime();
    }
    this.formData.externalCode =
      localStorage.getItem("scanText") || new Date().getTime();
  },
  methods: {
    toScan() {
      this.$router.push({ name: "qrcodeScan" });
    },
    // 调用移动端的扫码功能
    // 这里需要使用移动端的API，例如：
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
    scanQRCode() {
      console.log("调用移动端的扫码功能", navigator.mediaDevices);
      try {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(function (stream) {
            Toast.success(stream);
            const video = document.createElement("video");
            video.srcObject = stream;
            video.setAttribute("playsinline", true); // iOS使用
            video.play();
            Toast.success(video);
            requestAnimationFrame(function requestFrame() {
              const canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
              Toast.success(ctx);
              debugger;
              // const imageData = canvas.toDataURL("image/png");
              const imageData = ctx.getImageData(
                0,
                0,
                canvas.width,
                canvas.height
              );
              Toast.success(imageData);
              const code = jsQR(imageData.data, canvas.width, canvas.height);
              Toast.success(code);
              if (code) {
                console.log(code.data, "扫码结果"); // 扫码结果
                Toast.success(code.data);
                stream.getTracks().forEach(function (track) {
                  track.stop();
                });
              }
              requestAnimationFrame(requestFrame);
            });
          })
          .catch(function (error) {
            Toast.fail(error);
            if (error["name"] === "NotAllowedError") {
              Toast.fail("Please allow camera access to scan QR codes.");
            } else if (error["name"] === "NotFoundError") {
              Toast.fail("No camera found on this device.");
            } else if (error["name"] === "NotSupportedError") {
              Toast.fail("The browser does not support camera access.");
            } else if (error["name"] === "NotReadableError") {
              Toast.fail("Could not access the camera. Is it already in use?");
            } else {
              Toast.fail("Unknown error occurred: " + error.message);
            }
          });
      } catch (error) {
        Toast.fail(error);
      }
    },
    imageChange(formData) {
      this.formDataPic = formData;
    },
    async images_upload_handler() {
      const res = await api.uploadImage(this.formDataPic);
      this.formData.picture = res.data;
    },
    validData() {
      if (this.$utils.isEmpty(this.formData.name)) {
        this.$utils.createError("名称不允许为空！");
        return false;
      }
      if (this.$utils.isEmpty(this.formData.externalCode)) {
        this.$utils.createError("商品外部编码不允许为空！");
        return false;
      }
      if (this.$utils.isEmpty(this.formData.categoryId)) {
        this.$utils.createError("类目不允许为空！");
        return false;
      }

      if (this.$utils.isEmpty(this.formData.brandId)) {
        this.$utils.createError("品牌不允许为空！");
        return false;
      }

      if (!this.$utils.isEmpty(this.formData.weight)) {
        if (!this.$utils.isFloat(this.formData.weight)) {
          this.$utils.createError("重量（kg）必须为数字");
          return false;
        }
        if (!this.$utils.isFloatGeZero(this.formData.weight)) {
          this.$utils.createError("重量（kg）不允许小于0");
          return false;
        }
        if (!this.$utils.isNumberPrecision(this.formData.weight, 2)) {
          this.$utils.createError("重量（kg）最多允许2位小数");
          return false;
        }
      }
      if (!this.$utils.isEmpty(this.formData.volume)) {
        if (!this.$utils.isFloat(this.formData.volume)) {
          this.$utils.createError("体积（cm³）必须为数字");
          return false;
        }
        if (!this.$utils.isFloatGeZero(this.formData.volume)) {
          this.$utils.createError("体积（cm³）不允许小于0");
          return false;
        }
        if (!this.$utils.isNumberPrecision(this.formData.volume, 2)) {
          this.$utils.createError("体积（cm³）最多允许2位小数");
          return false;
        }
      }
      if (this.$utils.isEmpty(this.formData.taxRate)) {
        this.$utils.createError("进项税率（%）不允许为空！");
        return false;
      }
      if (!this.$utils.isEmpty(this.formData.taxRate)) {
        if (!this.$utils.isInteger(this.formData.taxRate)) {
          this.$utils.createError("进项税率（%）必须为整数");
          return false;
        }
        if (!this.$utils.isIntegerGeZero(this.formData.taxRate)) {
          this.$utils.createError("进项税率（%）不允许小于0");
          return false;
        }
      }
      if (this.$utils.isEmpty(this.formData.saleTaxRate)) {
        this.$utils.createError("进项税率（%）不允许为空！");
        return false;
      }
      if (!this.$utils.isEmpty(this.formData.saleTaxRate)) {
        if (!this.$utils.isInteger(this.formData.saleTaxRate)) {
          this.$utils.createError("销项税率（%）必须为整数");
          return false;
        }
        if (!this.$utils.isIntegerGeZero(this.formData.saleTaxRate)) {
          this.$utils.createError("销项税率（%）不允许小于0");
          return false;
        }
      }
      if (this.$utils.isEmpty(this.formData.purchasePrice)) {
        this.$utils.createError("采购价（元）不允许为空！");
        return false;
      }
      if (!this.$utils.isEmpty(this.formData.purchasePrice)) {
        if (!this.$utils.isFloat(this.formData.purchasePrice)) {
          this.$utils.createError("采购价（元）必须为数字");
          return false;
        }
        if (!this.$utils.isFloatGeZero(this.formData.purchasePrice)) {
          this.$utils.createError("采购价（元）不允许小于0");
          return false;
        }
        if (!this.$utils.isNumberPrecision(this.formData.purchasePrice, 2)) {
          this.$utils.createError("采购价（元）最多允许2位小数");
          return false;
        }
      }
      if (this.$utils.isEmpty(this.formData.salePrice)) {
        this.$utils.createError("销售价（元）不允许为空！");
        return false;
      }
      if (!this.$utils.isEmpty(this.formData.salePrice)) {
        if (!this.$utils.isFloat(this.formData.salePrice)) {
          this.$utils.createError("销售价（元）必须为数字");
          return false;
        }
        if (!this.$utils.isFloatGeZero(this.formData.salePrice)) {
          this.$utils.createError("销售价（元）不允许小于0");
          return false;
        }
        if (!this.$utils.isNumberPrecision(this.formData.salePrice, 2)) {
          this.$utils.createError("销售价（元）最多允许2位小数");
          return false;
        }
      }
      if (this.$utils.isEmpty(this.formData.retailPrice)) {
        this.$utils.createError("零售价（元）不允许为空！");
        return false;
      }
      if (!this.$utils.isEmpty(this.formData.retailPrice)) {
        if (!this.$utils.isFloat(this.formData.retailPrice)) {
          this.$utils.createError("零售价（元）必须为数字");
          return false;
        }
        if (!this.$utils.isFloatGeZero(this.formData.retailPrice)) {
          this.$utils.createError("零售价（元）不允许小于0");
          return false;
        }
        if (!this.$utils.isNumberPrecision(this.formData.retailPrice, 2)) {
          this.$utils.createError("零售价（元）最多允许2位小数");
          return false;
        }
      }
      return true;
    },
    categorySelectConfirm(row) {
      if (!Object.keys(row).length) {
        this.categorySelectVisible = false;
        return;
      }
      this.categorySelectVisible = false;
      this.formData.categoryName = row.name;
      this.formData.categoryId = row.id;
    },
    brandSelectConfirm(row) {
      if (!Object.keys(row).length) {
        this.brandSelectVisible = false;
        return;
      }
      this.brandSelectVisible = false;
      this.formData.brandName = row.name;
      this.formData.brandId = row.id;
    },
    showBrandDialog() {
      this.brandSelectVisible = true;
    },
    showDialog() {
      this.categorySelectVisible = true;
    },
    cancel() {
      this.showPicker = false;
    },
    async onSubmit() {
      if (!this.validData()) {
        return;
      }
      try {
        if (this.formDataPic) {
          await this.images_upload_handler();
        }
        this.loading = true;
        const data = {
          ...this.formData,
          available: this.formData.available === "1" ? true : false,
        };
        const res = await create(data);
        if (res.code === 200) {
          Toast.success("新增商品成功");
          this.loading = false;
          this.$router.push("/product/category");
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
::v-deep .van-field__body {
  height: 100%;
}
.loadingBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  justify-content: center;
}
.van-icon {
  font-size: 22px;
}
</style>
