<template>
  <div class="custom-table">
    <ve-table
      v-if="radioFlag"
      :scroll-width="scrollWidth"
      :max-height="maxHeight"
      :columns="columns"
      :fixed-header="true"
      :table-data="tableData"
      :border-around="true"
      :border-x="true"
      :border-y="true"
      style="word-break: break-all; width: 100%"
      :event-custom-option="eventCustomOption"
      :row-key-field-name="rowKeyFieldName"
      :radio-option="radioOption"
      :checkbox-option="checkboxOption"
    />
    <ve-table
      v-else
      :scroll-width="scrollWidth"
      :max-height="maxHeight"
      :columns="columns"
      :fixed-header="true"
      :table-data="tableData"
      :border-around="true"
      :border-x="true"
      :border-y="true"
      style="word-break: break-all; width: 100%"
      :event-custom-option="eventCustomOption"
    />
    <ve-pagination
      v-if="showPagination"
      :total="total"
      :page-index="index"
      :page-size="size"
      :layout="layout"
      :paging-count="2"
      :page-size-option="pageSizeOption"
      @on-page-number-change="pageNumberChange"
      @on-page-size-change="pageSizeChange"
    />
  </div>
</template>
<script>
export default {
  name: "CustomTable",
  props: {
    scrollWidth: { default: 1000, type: Number | String },
    maxHeight: { default: 500, type: Number | String },
    tableData: Array,
    columns: Array,
    showPagination: { type: Boolean, default: true },
    total: { default: 0, type: Number },
    pageSize: { default: 10, type: Number },
    pageIndex: { default: 1, type: Number },
    rowKeyFieldName: { default: "id", type: String },
  },
  data() {
    return {
      pageSizeOption: [],
      radioOption: {
        selectedRowKey: "",
        selectedRowChange: ({ row }) => {
          console.log(row)
          this.changeSelectedRowKeyRadio(row);
          this.$emit("selectedRowChange", row);
        },
      },
      checkboxOption: {
        selectedRowKeys: [],
        // 行选择改变事件
        selectedRowChange: (e) => {
          console.log(e);
          this.changeSelectedRowKeys(e);
          this.$emit("selectedRowChange", e);
        },
        // 全选改变事件
        selectedAllChange: (e) => {
          console.log(e);
          this.changeSelectedRowKeys(e);
          this.$emit("selectedRowChange", e);
        },
      },
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            click: (event) => {
              this.$emit("rowClick", { row, rowIndex, event });
            },
            // dblclick: (event) => {
            //   console.log("dbclick::", row, rowIndex, event);
            //   this.$emit("rowClick",{row, rowIndex, event})
            // },
            // contextmenu: (event) => {
            //   console.log("contextmenu::", event);
            // },
            // mouseenter: (event) => {
            //   console.log("mouseenter::",  event);
            // },
            // mouseleave: (event) => {
            //   console.log("mouseleave::",event);
            // },
          };
        },
      },
      layout: ["total", "prev", "pager", "next", "sizer"],
      index: 10,
      size: 1,
      radioFlag: false,
    };
  },

  watch: {
    pageIndex: {
      handler(newVal, oldVal) {
        this.index = newVal;
      },
      immediate: true,
    },
    pageSize: {
      handler(newVal, oldVal) {
        this.size = newVal;
      },
      immediate: true,
    },
    total: {
      handler(newVal, oldVal) {
        this.pageSizeOption =
          newVal > 100 ? [5, 10, 20, 40, 60, 80] : [5, 10, 20, 30];
      },
      immediate: true,
    },
    columns: {
      handler(newVal, oldVal) {
        this.radioFlag = newVal.some(
          (el) => el.type === "radio" || el.type === "checkbox"
        );
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  computed: {},
  mounted() {
    // console.log(this.$props);
  },
  methods: {
    unselectedAll() {
      this.checkboxOption.selectedRowKeys = [];
      this.radioOption.selectedRowKey = "";
    },
    changeSelectedRowKeyRadio(e) {
      this.radioOption.selectedRowKey = e.productId||e.id;
    },
    changeSelectedRowKeys(e) {
      this.checkboxOption.selectedRowKeys = e.selectedRowKeys;
    },
    pageNumberChange(val) {
      this.$emit("pageChange", { pageIndex: val, pageSize: this.size });
    },
    pageSizeChange(val) {
      this.$emit("pageChange", { pageIndex: this.index, pageSize: val });
    },
  },
};
</script>
<style lang="less" scoped>
.custom-table {
  overflow: auto;
  background-color: #efefef;
  box-sizing: border-box;
}
.ve-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

::v-deep .ve-pagination-pager {
  display: flex;
  flex-direction: row;
}
</style>
