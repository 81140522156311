<template>
  <div>
    <header class="pt10">
      <DateChange ref="dateChange" @change="handlerDateChange" />
    </header>
    <div class="p15 bg-white">
      <van-tabs v-model="activeName">
        <van-tab title="商品排行" name="product"></van-tab>
        <van-tab title="品类排行" name="category"></van-tab>
      </van-tabs>
      <div class="p10" v-if="activeName === 'product'">
        <div class="flex flex-between">
          <div class="fs16">商品销售额商品排行(TOP10)</div>
          <div class="gray-7 fs13" @click="toDetail">查看明细</div>
        </div>
        <div>
          <div v-for="(item, i) in productList" class="item">
            <div class="flex flex-start">
              <div class="w20">
                <van-icon v-if="i < 3" name="award" :color="color[i]" />
                <span v-else class="mainColor fb">{{ i + 1 }}</span>
              </div>

              <span class="fs16 p5">{{ item.productName }}</span>
            </div>
            <div class="flex flex-between fs14 pt5">
              <div>销售总额</div>
              <div class="fb grey-8 fs16">{{ item.totalAmount }}</div>
            </div>
            <div class="flex flex-between fs14 pt5">
              <div>销售单数</div>
              <div class="fb grey-8 fs16">{{ item.orderCnt }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="p10" v-else-if="activeName === 'category'">
        <div class="flex flex-between">
          <div class="fs16">品类销售额品类排行(TOP10)</div>
          <div class="gray-7 fs13" @click="toDetail">查看明细</div>
        </div>
        <div>
          <div v-for="(item, i) in categoryList" class="item">
            <div class="flex flex-start">
              <div class="w20">
                <van-icon v-if="i < 3" name="award" :color="color[i]" />
                <span v-else class="mainColor fb">{{ i + 1 }}</span>
              </div>
              <span class="fs16 p5">{{ item.categoryName }}</span>
            </div>
            <div class="flex flex-between fs14 pt5">
              <div>销售总额</div>
              <div class="fb grey-8 fs16">{{ item.totalAmount }}</div>
            </div>
            <div class="flex flex-between fs14 pt5">
              <div>销售单数</div>
              <div class="fb grey-8 fs16">{{ item.orderCnt }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateChange from "@/components/dateChange.vue";
import { sortByPage, categoryByPage } from "../../../service/api/home";

export default {
  name: "index",
  components: { DateChange },
  data() {
    return {
      activeName: "product",
      productList: [],
      categoryList: [],
      color: ["red", "orange", "orange-light"],
      params: {
        startDate: "",
        endDate: "",
      },
      totalCount: 0,
      totalPage: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  mounted() {
    this.params.startDate = this.$refs.dateChange.formattedStartDate;
    this.params.endDate = this.$refs.dateChange.formattedEndDate;
    this.getList();
  },
  methods: {
    toOrderList(item) {
      this.$router.push("/analysis/orderList/" + item.productId);
    },
    getList() {
      const params = {
        ...this.params,
        productId: this.id,
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      };
      sortByPage(params).then((res) => {
        this.totalCount = res.data.totalCount;
        this.totalPage = res.data.totalPage;

        this.productList = res.data.datas;
      });
      categoryByPage(params).then((res) => {
        this.categoryList = res.data.datas;
      });
    },
    toDetail() {
      this.$router.push("/analysis/" + this.activeName);
    },
    handlerDateChange(startDate, endDate) {
      this.pageIndex = 1;
      this.finished = false;
      this.params = { ...this.params, startDate, endDate };
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .van-tabs__line {
  background-color: #4c9673;
}
.item {
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
}
</style>
