<template>
  <div>
    <van-panel title="订单信息">
      <div class="item">
        <div class="flex flex-between grey-7 fs14">
          <div>订单号</div>
          <div>{{ form.id }}</div>
        </div>
        <div class="flex flex-between grey-7 fs14">
          <div>订单时间</div>
          <div>{{ form.createTime }}</div>
        </div>
      </div>
    </van-panel>
    <van-panel title="支付信息">
      <div class="item">
        <div class="flex flex-between grey-7 fs14">
          <div>订单总额</div>
          <div class="fb">{{ form.oriTotalAmount }}</div>
        </div>
        <div class="flex flex-between grey-7 fs14">
          <div>订单折扣</div>
          <div>{{ form.discountAmount }}</div>
        </div>
        <div class="flex flex-between grey-7 fs14">
          <div>实际应收</div>
          <div>{{ form.totalAmount }}</div>
        </div>
        <div class="flex flex-between grey-7 fs14">
          <div>商家实收</div>
          <div class="mainColor fb">{{ form.totalAmount }}</div>
        </div>
        <div
          class="flex flex-between grey-7 fs14"
          v-for="item in form.payTypes"
        >
          <div class="ml30">{{ item.payTypeName }}</div>
          <div class="mainColor fb">{{ item.payAmount }}</div>
        </div>
      </div>
    </van-panel>
    <van-panel title="商品详情">
      <div class="detail">
        <div class="item" v-for="el in form.details">
          <div class="flex flex-between grey-7 fs14">
            <div>{{ el.productName }}</div>
            <div class="red fb">￥{{ el.totalAmount }}</div>
          </div>
          <div class="flex flex-between grey-7 fs14">
            <div>
              <span class="mainColor fb">￥{{ el.retailPrice }}</span>
              <span>/{{ el.unit || "个" }}</span>
            </div>
            <div class="fb grey-8" v-if="el.actualWeight">
              {{ el.actualWeight }}{{ el.unit }}
            </div>
          </div>
        </div>
      </div>
    </van-panel>
  </div>
</template>
<script>
import { get } from "../../../service/api/retail/out";

export default {
  name: "index",
  data() {
    return {
      id: this.$route.params.id,
      form: {},
    };
  },
  mounted() {
    this.getDetail();
    console.log(this.$route);
  },
  methods: {
    getDetail() {
      get(this.id).then((res) => {
        const data = res.data;
        this.form = Object.assign({}, this.form, {
          id: data.id,
          createTime: data.createTime,
          totalAmount: data.totalAmount,
          discountAmount: data.discountAmount,
          oriTotalAmount: data.oriTotalAmount,
          createTime: data.createTime,
          payTypes: data.payTypes,
          details: data.details,
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  padding: 5px 15px;
  > div {
    padding: 3px 0;
  }
}
.van-panel {
  margin-bottom: 10px;
}
.van-cell__title {
  span {
    font-size: 18px;
    font-weight: 600;
  }
}
.detail {
  height: 440px;
  overflow: auto;
}
</style>
