<template>
  <div>
    <van-form style="position: relative; margin-top: 1rem">
      <van-cell-group inset>
        <van-sticky>
          <van-cell>
            <van-field
              v-model="formData.productName"
              name="productName"
              label="商品名称"
            />
          </van-cell>
          <van-cell>
            <van-field
              v-model="formData.lotCode"
              name="lotCode"
              label="批次号"
            />
          </van-cell>
          <van-cell>
            <van-field
              v-model="formData.numberOfDaysExpired"
              name="numberOfDaysExpired"
              label="过期天数"
            />
          </van-cell>
          <van-cell>
            <van-field
              v-model="formData.alertLevel"
              @click="openPicker('alertLevel')"
              name="alertLevel"
              label="预警级别"
            />
          </van-cell>
          <van-cell>
            <van-button :loading="loading" type="primary" @click="search"
              >查询</van-button
            >
          </van-cell>
        </van-sticky>
        <van-popup
          close-on-click-overlay
          v-model:show="showPicker"
          round
          position="bottom"
        >
          <van-picker
            show-toolbar
            :columns="alertLevelColumns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
      </van-cell-group>
      <CustomTable
        :scrollWidth="600"
        :columns="columns"
        :tableData="tableData"
        :maxHeight="maxHeight"
        :total="totalCount"
        :pageIndex="pageIndex"
        :pageSize="pageSize"
        @pageChange="pageChange"
        ref="customTable"
      ></CustomTable>
    </van-form>
  </div>
</template>
<script>
import { queryBydic, queryWarningProductList } from "../../service/api/product";
import CustomTable from "../../components/customTable";
export default {
  name: "index",
  components: { CustomTable },
  data() {
    return {
      formData: { productName: "", lotCode: "" },
      loading: true,
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      maxHeight: 400,
      columns: [
        {
          field: "productName",
          key: "productName",
          title: "商品名称",
        },
        { field: "lotCode", key: "lotCode", title: "批次号", width: 100 },
        { field: "shelfLife", key: "shelfLife", title: "保质期", width: 100 },
        {
          field: "numberOfDaysExpired",
          key: "numberOfDaysExpired",
          title: "过期天数",
          width: 50,
        },
      ],
      showPicker: false,
      key: "",
      alertLevelColumns: [],
    };
  },
  created() {
    this.maxHeight = window.innerHeight * 0.7 - 200;
    window.addEventListener("resize", () => {
      this.maxHeight = window.innerHeight * 0.7 - 200;
    });
  },
  mounted() {
    queryBydic().then((res) => {
      // YJYJ EJYJ SJYJ
      this.alertLevelColumns = res.data.map((item) => {
        item.text =
          item.code === "YJYJ"
            ? "一级预警"
            : item.code === "EJYJ"
            ? "二级预警"
            : item.code === "SJYJ"
            ? "三级预警"
            : "";
        return item;
      });
    });
    this.getList();
  },
  methods: {
    openPicker(key) {
      this.showPicker = true;
      this.key = key;
    },
    onConfirm(value) {
      this.formData[this.key] = value.name;
      this.showPicker = false;
    },
    pageChange(pageOptions) {
      this.pageIndex = pageOptions.pageIndex;
      this.pageSize = pageOptions.pageSize;
      this.getList();
    },
    search() {
      this.getList();
    },
    getList() {
      this.loading = true;
      const params = Object.assign({}, this.formData, {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      });
      queryWarningProductList(params)
        .then((res) => {
          this.tableData = res.data.datas;
          this.loading = false;
          this.totalCount = res.data.totalCount;
          this.totalPage = res.data.totalPage;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
