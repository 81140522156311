<template>
  <div class="page-scan">
    <!-- 扫描盒子 -->
    <div class="scan-box" v-if="scanTextData.showScanBox">
      <div
        class="scan-cacel"
        @click="cancelScan"
        v-show="scanTextData.showScanBoxInfo"
      >
        ×
      </div>
      <video
        ref="video"
        id="video"
        class="scan-video"
        v-show="scanTextData.showScanBoxInfo"
        autoplay
      ></video>
      <div class="scan-img" v-show="scanTextData.showScanBoxInfo">
        <div class="scan-frame">
          <span class="cross-line"></span>
        </div>
      </div>
      <div class="scan-tip" v-show="scanTextData.showScanBoxInfo">
        {{ scanTextData.tipMsg }}
      </div>
    </div>
    <div class="photo-wrap">
      <div class="photo" @click="handleOpenFile">
        <van-icon name="photo-o" />
      </div>
      <div class="tit">相册</div>
    </div>
  </div>
</template>

<script>
import { BrowserMultiFormatReader } from "@zxing/library";
import { Toast } from "vant";
import { fileOpen } from "browser-fs-access";
import Quagga from "quagga";

export default {
  name: "scanCodePage",
  data() {
    return {
      scanTextData: {
        loadingShow: true,
        codeReader: null,
        scanText: "",
        vin: null,
        tipMsg: "识别二维码、条形码",
        tipShow: true,
        showScanBox: true,
        showScanBoxInfo: true,
      },
      hasBind: false,
      addForm: { express_number: "" },
      express_img: "",
    };
  },
  mounted() {
    this.toScanCode();
  },
  activated() {
    this.toScanCode();
  },
  methods: {
    checkCameraSupport() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        return true;
      } else {
        return false;
      }
    },
    requestCameraAccess() {
      return navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((stream) => {
          console.log("已授予摄像头权限");
          return stream;
        })
        .catch((err) => {
          throw err;
        });
    },
    // 打开图片选择
    async handleOpenFile() {
      try {
        const file = await fileOpen({ mimeTypes: ["image/*"] }).catch(
          () => null
        );
        if (!file) return;
        // 计算文件的大小
        const fileSizeMb = _.round(file.size / 1024 / 1024, 2);
        // 文件大小不能超过 10MB
        const limitSizeMb = 10;
        if (fileSizeMb > limitSizeMb) {
          return ElMessage.warning(`图片大小限制 ${limitSizeMb}MB`);
        }
        this.processFile(file);
      } catch (error) {
        console.log(`[log] - handleOpenUploadIcon - error:`, error);
      }
    },
    async processFile(file) {
      const _this = this;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      debugger;
      reader.onload = () => {
        let img = new Image();
        img.src = reader.result;
        _this.init(img.src);
      };
    },
    init(src) {
      let _this = this;
      const config = {
        locator: {
          patchSize: "medium",
          halfSample: true,
        },
        numOfWorkers: navigator.hardwareConcurrency
          ? navigator.hardwareConcurrency
          : 4,
        decoder: {
          readers: [
            "code_128_reader",
            "ean_reader",
            "ean_8_reader",
            "code_39_reader",
            "code_39_vin_reader",
            "codabar_reader",
            "upc_reader",
            "upc_e_reader",
            "i2of5_reader",
          ], // List of active readers
        },
        locate: true,
        src: src,
      };
      Quagga.decodeSingle(config, function (result) {
        if (!result) {
          Toast.fail("图片中没有条形码!");
          return false;
        }
        //识别结果
        if (result.codeResult) {
          _this.addForm.express_number = result.codeResult.code;
          _this.express_img = src; // 图片回显
          _this.reset(result.codeResult.code);
        } else {
          Toast.fail("未识别图片中条形码!");
        }
      });
    },
    // 展示扫码盒子
    toScanCode() {
      if (this.checkCameraSupport()) {
        this.requestCameraAccess()
          .then((stream) => {
            console.log("已授予摄像头权限", stream);
            let thisVideo = document.getElementById("video");
            thisVideo.srcObject.getTracks()[0].stop();
            this.scanTextData.codeReader.reset(); // 重置
            this.scanTextData.showScanBox = false;
            setTimeout(() => {
              this.scanTextData.showScanBoxInfo = false;
            }, 1000);
            this.$router.push("/product/add");
          })
          .catch((err) => {
            console.log("requestCameraAccess---false", err);
            Toast.fail(err);
          });
      } else {
        console.log("toScanCode---无法访问摄像设备，请开放权限");
        Toast.fail("无法访问摄像设备，请开放权限");
      }
      this.scanTextData.codeReader = new BrowserMultiFormatReader();
      this.scanTextData.showScanBox = true;
      // 调用摄像头
      this.openScan();
    },
    cancelScan() {
      debugger;
      //识别完停止使用摄像头
      try {
        let thisVideo = document.getElementById("video");
        thisVideo.srcObject.getTracks()[0].stop();
        this.scanTextData.codeReader.reset(); // 重置
        this.scanTextData.showScanBox = false;
        setTimeout(() => {
          this.scanTextData.showScanBoxInfo = false;
        }, 1000);
        this.$router.push("/product/add");
      } catch (error) {
        console.log(error);
        this.scanTextData.showScanBoxInfo = false;
        this.$router.push("/product/add");
      }
    },
    // 调用摄像头
    async openScan() {
      this.scanTextData.codeReader
        .getVideoInputDevices()
        .then((videoInputDevices) => {
          console.log("openScan-------videoInputDevices", videoInputDevices);
          this.scanTextData.tipShow = true;
          this.scanTextData.tipMsg = "正在调用摄像头...";
          console.log("videoInputDevices", videoInputDevices[0].label);
          // 默认获取第一个摄像头设备id
          let firstDeviceId = videoInputDevices[0].deviceId;
          // 获取第一个摄像头设备的名称
          const videoInputDeviceslablestr = JSON.stringify(
            videoInputDevices[0].label
          );
          if (videoInputDevices.length > 1) {
            // 判断是否后置摄像头
            if (videoInputDeviceslablestr.indexOf("back") > -1) {
              firstDeviceId = videoInputDevices[0].deviceId;
              console.log("后置摄像头");
            } else {
              firstDeviceId = videoInputDevices[1].deviceId;
              console.log("前置摄像头");
            }
          }
          this.decodeFromInputVideoFunc(firstDeviceId);
        })
        .catch((err) => {
          console.log("openScan-------videoInputDevices-----err", err);
          this.scanTextData.tipShow = false;
          Toast.fail("无法访问视频源设备，请开放权限");
        });
    },
    reset(text) {
      Toast.success(`识别成功-${text}`);
      this.scanTextData.showScanBox = false;
      this.scanTextData.showScanBoxInfo = false;
      localStorage.setItem("scanText", text);
      this.$router.push("/product/add");
      // 这里扫描出结果可以调用你想要的方法
      // 识别完停止使用摄像头
      let thisVideo = document.getElementById("video");
      thisVideo.srcObject.getTracks()[0].stop();
      this.scanTextData.codeReader.reset(); // 重置
    },
    decodeFromInputVideoFunc(firstDeviceId) {
      console.log("decodeFromInputVideoFunc---firstDeviceId:", firstDeviceId);
      this.scanTextData.codeReader.reset(); // 重置
      this.scanTextData.scanText = "";
      this.scanTextData.codeReader.decodeFromInputVideoDeviceContinuously(
        firstDeviceId,
        "video",
        (result, err) => {
          this.scanTextData.tipMsg = "识别二维码、条形码";
          this.scanTextData.scanText = "";
          setTimeout(() => {
            this.scanTextData.showScanBoxInfo = true;
          }, 1000);
          if (result) {
            console.log("扫描结果", result, result.text);
            if (result.text) {
              this.reset(result.text);
            }
          } else {
            // console.log('没出来？',result,err)
          }
          if (err && !err) {
            this.scanTextData.tipMsg = "识别失败";
            setTimeout(() => {
              this.scanTextData.tipShow = false;
            }, 2000);
            console.error(err);
          }
        }
      );
    },
  },
  props: ["dataObj"],
};
</script>

<style lang="less" scoped>
.pullWrap {
  width: 100%;
  height: 100px;
  padding-top: 50px;
  background: #fff;

  .topTitle {
    width: 100%;
    position: relative;

    .pullTitle {
      display: flex;
      height: 80px;
      line-height: 80px;
      margin-top: -40px;
      background: #fff;
      align-items: center;
      justify-content: space-between;

      .pullName {
        font-size: 30px;
        color: rgba(0, 0, 0, 0.85);
      }

      img {
        width: 40px;
        height: 40px;
      }

      .left-icon {
        margin: 0 15px;
      }

      span {
        text-decoration: underline;
        text-decoration-color: #42a5ff;
        color: #42a5ff;
        margin-left: 5px;
      }

      .right-part {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-bottom: 1px solid #e5e5e5;
        overflow: hidden;
        text-overflow: ellipsis;

        input {
          border: none;
          flex: 1;
        }
      }
    }
  }
}

.scan-index-bar {
  background-image: linear-gradient(-45deg, #42a5ff, #59cfff);
}

.van-nav-bar__title {
  color: #fff !important;
}

.scan-box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  width: 100vw;

  .scan-cacel {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 10009;
    color: #333;
    font-size: 20px;
    background: #f2f2f2;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.scan-video {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.scan-img {
  width: 80vw;
  height: 80vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;

  .scan-frame {
    width: 100%;
    height: 100%;
    position: relative;

    .left-t,
    .right-t,
    .left-b,
    .right-b {
      position: absolute;
      width: 80px;
      height: 80px;
    }

    .left-t {
      top: 0;
      left: 0;
      border-top: 2px solid #17b1b7;
      border-left: 2px solid #17b1b7;
    }

    .right-t {
      top: 0;
      right: 0;
      border-top: 2px solid #17b1b7;
      border-right: 2px solid #17b1b7;
    }

    .left-b {
      bottom: 0;
      left: 0;
      border-bottom: 2px solid #17b1b7;
      border-left: 2px solid #17b1b7;
    }

    .right-b {
      bottom: 0;
      right: 0;
      border-bottom: 2px solid #17b1b7;
      border-right: 2px solid #17b1b7;
    }

    .cross-line {
      width: 80vw;
      height: 10px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        #5dddd3,
        rgba(255, 255, 255, 0)
      );
      position: absolute;
      top: 0;
      // left: -50px;
      animation: identifier_p 5s infinite;
    }

    @keyframes identifier_p {
      0% {
        top: 0%;
      }

      50% {
        top: 100%;
      }

      100% {
        top: 0;
      }
    }
  }
}

.scan-tip {
  width: 100vw;
  text-align: center;
  margin-bottom: 10vh;
  color: white;
  font-size: 5vw;
  position: absolute;
  bottom: 40px;
  left: 0;
  color: #fff;
}

.page-scan {
  overflow-y: hidden;
  // background-color: #363636;
}

.photo-wrap {
  position: fixed;
  bottom: 1.5rem;
  left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  z-index: 10009;
}

.photo {
  height: 3.125rem;
  width: 3.125rem;
  background-color: rgba(250, 250, 250, 0.8);
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.tit {
  color: #fff;
  font-size: 18px;
}
</style>
