<template>
  <div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="formData.retailOutSheetRequireMember"
            name="retailOutSheetRequireMember"
            label="零售出库单上的会员是否必填："
            required
            readonly
            @click="openPicker('retailOutSheetRequireMember')"
          >
          </van-field>
        </van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="formData.retailOutSheetRequireLogistics"
            name="retailOutSheetRequireLogistics"
            label="零售出库单是否需要发货："
            readonly
            required
            @click="openPicker('retailOutSheetRequireLogistics')"
          >
          </van-field>
        </van-cell>
      </van-cell-group>
      <van-cell-group inset>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="formData.retailReturnRequireMember"
            name="retailReturnRequireMember"
            label="零售退货单上的会员是否必填："
            readonly
            required
            @click="openPicker('retailReturnRequireMember')"
          >
          </van-field>
        </van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="formData.retailReturnRequireOutStock"
            name="retailReturnRequireOutStock"
            label="零售退货单是否关联零售出库单："
            readonly
            required
            @click="openPicker('retailReturnRequireOutStock')"
          >
          </van-field>
        </van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="formData.retailReturnMultipleRelateOutStock"
            name="retailReturnMultipleRelateOutStock"
            label="零售退货单是否多次关联零售出库单"
            readonly
            required
            @click="openPicker('retailReturnMultipleRelateOutStock')"
          >
          </van-field>
        </van-cell>
      </van-cell-group>
      <van-popup
        close-on-click-overlay
        v-model:show="showPicker"
        round
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { get, update } from "../../../service/api/retail/config";

export default {
  name: "index",
  data() {
    return {
      formData: {},
      key: "",
      showPicker: false,
      columns: [{ text: "是" }, { text: "否" }],
    };
  },
  created() {
    get().then((res) => {
      for (const key in res.data) {
        if (Object.prototype.hasOwnProperty.call(res.data, key)) {
          const value = res.data[key] ? "是" : "否";
          this.formData[key] = value;
        }
      }
    });
  },
  methods: {
    openPicker(key) {
      this.showPicker = true;
      this.key = key;
    },
    onConfirm(value) {
      this.formData[this.key] = value.text;
      this.showPicker = false;
    },
    onSubmit() {
      let params = {};
      for (const key in this.formData) {
        if (Object.prototype.hasOwnProperty.call(this.formData, key)) {
          const value = this.formData[key] === "是" ? true : false;
          params[key] = value;
        }
      }
      update(params).then((res) => {});
    },
  },
};
</script>
<style lang="less" scoped>
* {
  touch-action: pan-y;
}
</style>
