<template>
  <div class="container">
    <div class="dateRangeContainer flex flex-between">
      <div :span="14" class="flex flex-center">{{ title }}</div>
      <div :span="8" class="dateTypeContainer">
        <div class="flex flex-center">
          <van-col
            span="8"
            :class="dateType === 0 ? 'active item' : 'item'"
            @click="changeDateType(0)"
            >日</van-col
          >
          <van-col
            span="8"
            :class="dateType === 1 ? 'active item' : 'item'"
            @click="changeDateType(1)"
            >周</van-col
          >
          <van-col
            span="8"
            :class="dateType === 2 ? 'active item' : 'item'"
            @click="changeDateType(2)"
            >月</van-col
          >
        </div>
      </div>
    </div>
    <van-row class="flex flex-center fs14 pt10 pb10 bg2" v-if="showDate">
      <van-col :span="4" class="flex flex-center">
        <div @click="prevDateRange"><van-icon name="arrow-left" /></div>
      </van-col>
      <van-col :span="16" class="flex flex-evenly">
        <span>{{ formattedStartDate }}</span> <span class="fs12">至</span>
        <span>{{ formattedEndDate }}</span>
      </van-col>
      <van-col :span="4" class="flex flex-center">
        <div @click="nextDateRange"><van-icon name="arrow" /></div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { addDate, parseDate } from "../utils";
import dayjs from "dayjs";

export default {
  data() {
    return {
      dateType: 0,
      selectedDate: new Date(),
      selectedWeekDate: new Date(),
      selectedMonthDate: new Date(),

      //  new Date(),
    };
  },
  props: {
    title: String,
    showDate: { type: Boolean, default: true },
  },
  created() {
    const data = JSON.parse(localStorage.getItem("dateChange"));
    if (data && this.showDate) {
      this.dateType = data.dateType;
      this.selectedDate = data.selectedDate;
      this.selectedWeekDate = data.selectedWeekDate;
      this.selectedMonthDate = data.selectedMonthDate;
    }
  },
  computed: {
    formattedStartDate() {
      if (this.dateType === 0) {
        return parseDate(this.selectedDate);
      } else if (this.dateType === 1) {
        return this.getFirstDayOfWeek(this.selectedWeekDate);
      } else if (this.dateType === 2) {
        return this.getFirstDayOfMonth(this.selectedMonthDate);
      }
    },
    formattedEndDate() {
      if (this.dateType === 0) {
        return parseDate(this.selectedDate);
      } else if (this.dateType === 1) {
        return this.getLastDayOfWeek(this.selectedWeekDate);
      } else if (this.dateType === 2) {
        return this.getLastDayOfMonth(this.selectedMonthDate);
      }
    },
  },
  methods: {
    handleEmit() {
      localStorage.setItem(
        "dateChange",
        JSON.stringify({
          dateType: this.dateType,
          selectedDate: this.selectedDate,
          selectedWeekDate: this.selectedWeekDate,
          selectedMonthDate: this.selectedMonthDate,
        })
      );
      this.$emit("change", this.formattedStartDate, this.formattedEndDate);
    },
    changeDateType(index) {
      this.dateType = index;
      this.$nextTick(() => {
        this.handleEmit();
      });
    },
    getLastDayOfPreviousWeek(date) {
      // 复制传入的日期以避免修改原始日期
      const currentDate = new Date(date);

      // 获取当前日期是星期几（0 表示星期日，1 表示星期一，依此类推）
      const dayOfWeek = currentDate.getDay();

      // 计算上周的最后一天（星期六）的日期
      // 如果今天是星期日（0），则减去 1 天；如果是星期一（1），则减去 2 天，依此类推
      const diff = dayOfWeek + 1; // 1 表示今天到上周日的天数
      const lastDayOfPreviousWeek = new Date(
        currentDate.setDate(currentDate.getDate() - diff)
      );

      // 将日期设置为上周的星期六
      lastDayOfPreviousWeek.setDate(lastDayOfPreviousWeek.getDate() + 6);

      return lastDayOfPreviousWeek;
    },

    prevDateRange() {
      if (this.dateType === 0) {
        this.selectedDate = new Date(addDate(this.selectedDate, -1, "day"));
      }
      if (this.dateType === 1) {
        this.selectedWeekDate = new Date(
          addDate(this.selectedWeekDate, -1, "week")
        );
      }
      if (this.dateType === 2) {
        this.selectedMonthDate = new Date(
          addDate(this.selectedMonthDate, -1, "month")
        );
      }
      this.$nextTick(() => {
        this.handleEmit();
      });
    },
    nextDateRange() {
      if (dayjs(this.formattedEndDate).isSame(dayjs(new Date()), "day")) {
        return;
      }
      if (this.dateType === 0) {
        this.selectedDate = new Date(addDate(this.selectedDate, 1, "day"));
      }
      if (this.dateType === 1) {
        this.selectedWeekDate = new Date(
          addDate(this.selectedWeekDate, 1, "week")
        );
      }
      if (this.dateType === 2) {
        this.selectedMonthDate = new Date(
          addDate(this.selectedMonthDate, 1, "month")
        );
      }
      this.$nextTick(() => {
        this.handleEmit();
      });
    },
    getFirstDayOfWeek(date) {
      date = new Date(date);
      const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
      const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust when day is Sunday
      const res = parseDate(new Date(date.setDate(diff)));
      return this.diffDate(res);
    },

    getLastDayOfWeek(date) {
      const res = dayjs(date).endOf("week").add(1, "day").format("YYYY-MM-DD");
      return this.diffDate(res);
    },
    getFirstDayOfMonth(date) {
      date = new Date(date);
      const res = parseDate(new Date(date.getFullYear(), date.getMonth(), 1));
      return this.diffDate(res);
    },

    getLastDayOfMonth(date) {
      date = new Date(date);
      const res = parseDate(
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
      );
      return this.diffDate(res);
    },
    diffDate(date1) {
      const date2 = parseDate(new Date());
      return date1 < date2 ? date1 : date2;
    },
  },
};
</script>

<style scoped lang="less">
.container {
  background-color: transparent;
}
.dateRangeContainer {
  padding: 10px;
}
.dateTypeContainer {
  background-color: rgba(130, 183, 157, 1);
  padding: 5px 0;
  border-radius: 20px;
  width: 175px;

  .item {
    font-size: 14px;
    width: 50px;
    height: 26px;
    text-align: center;
    line-height: 25px;
    border-radius: 20px;
    color: #fff;
    margin: 0 5px;
  }
  .active.item {
    background-color: #ffffff;
    color: #4c9673;
  }
}
.bg2 {
  background-color: #3c8260;
}
</style>
