<template>
  <div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="formData.code"
            name="code"
            label="单据号"
          >
          </van-field>
        </van-cell>
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="formData.scId"
            name="scId"
            label="仓库"
          >
          </van-field>
        </van-cell> -->
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="formData.supplierId"
            name="supplierId"
            label="供应商"
          >
          </van-field>
        </van-cell> -->
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="formData.createBy"
            name="createBy"
            label="操作人"
          >
          </van-field>
        </van-cell> -->
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="formData.createStartTime"
            name="createStartTime"
            label="操作日期"
            readonly
            @click="openDatePicker('createStartTime')"
          >
          </van-field>
          <van-field
            autocomplete="off"
            v-model="formData.createEndTime"
            name="createEndTime"
            label="至"
            readonly
            @click="openDatePicker('createEndTime')"
          >
          </van-field>
        </van-cell>
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="formData.approveBy"
            name="approveBy"
            label="审核人"
          >
          </van-field>
        </van-cell> -->
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="formData.approveStartTime"
            name="approveStartTime"
            label="审核日期"
            readonly
            @click="openDatePicker('approveStartTime')"
          >
          </van-field>
          <van-field
           autocomplete="off"
            v-model="formData.approveEndTime"
            name="approveEndTime"
            label="至"
            readonly
            @click="openDatePicker('approveEndTime')"
          >
          </van-field>
        </van-cell> -->
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="formData.purchaser"
            name="purchaser"
            label="采购员"
          >
          </van-field>
        </van-cell> -->
        <van-cell @click="showStatusPicker = true">
          <van-field
            autocomplete="off"
            v-model="formData.statusText"
            label="状态"
            clearable
          ></van-field>
        </van-cell>
        <van-popup
          close-on-click-overlay
          v-model:show="showStatusPicker"
          round
          position="bottom"
        >
          <van-picker
            show-toolbar
            :columns="statusColumns"
            @cancel="showStatusPicker = false"
            @confirm="onStatusSelectConfirm"
          />
        </van-popup>
        <van-cell>
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            :loading="loading"
            loading-text="查询中..."
          >
            查询
          </van-button>
        </van-cell>
      </van-cell-group>

      <DateTimePicker
        :showPicker="showDatePicker"
        @confirm="onConfirm"
        @cancel="showDatePicker = false"
      ></DateTimePicker>
    </van-form>
    <div style="margin: 16px">
      <div class="action">
        <Van-icon
          v-permission="'purchase:order:add'"
          name="add-o"
          type="primary"
          @click="$router.push('/purchase/orderAdd')"
        ></Van-icon>
      </div>
      <CustomTable
        :scrollWidth="800"
        :columns="columns"
        :tableData="tableData"
        :total="totalCount"
        :pageIndex="pageIndex"
        :pageSize="pageSize"
        @pageChange="pageChange"
        @rowClick="rowClick"
      ></CustomTable>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { query } from "../../../service/api/purchase/order";
import CustomTable from "../../../components/customTable.vue";
import {
  PURCHASE_ORDER_STATUS,
  getTextByValue,
} from "../../../utils/constants";
import DateTimePicker from "../../../components/dateTimePicker";
import { oneMonthAgoMidnight } from "../../../utils";
export default {
  name: "index",
  components: {
    CustomTable,
    DateTimePicker,
  },
  data() {
    return {
      loading: false,
      showStatusPicker: false,
      action: [
        { name: "审核通过", click: "" },
        { name: "审核拒绝", click: "" },
      ],
      showActions: false,
      columns: [
        {
          field: "code",
          width: 100,
          title: "单据号",
          key: "code",
          fixed: "left",
        },
        // {field:'code', title: "仓库编号", key: "code" },
        { field: "scName", width: 100, title: "仓库名称", key: "scName" },
        // {field:'supplierCode, width:'10%'',width:'15%', title: "供应商编号", key: "supplierCode" },
        {
          field: "supplierName",
          width: 100,
          title: "供应商名称",
          key: "supplierName",
        },
        {
          field: "totalAmount",
          width: 60,
          title: "采购总金额",
          key: "totalAmount",
        },
        {
          field: "totalNum",
          width: 60,
          title: "采购商品数量",
          key: "totalNum",
        },
        {
          field: "totalGiftNum",
          width: 60,
          title: "采购赠品数量",
          key: "totalGiftNum",
        },
        {
          field: "expectArriveDate",
          title: "预计到货日期",
          key: "expectArriveDate",
        },
        {
          field: "createTime",
          title: "操作时间",
          key: "createTime",
        },
        // {field:'createBy, width:100', title: "操作人", key: "createBy" },
        {
          field: "status",
          width: 60,
          title: "状态",
          fixed: "right",
          key: "status",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const statusClass =
              row.status == "0"
                ? "color1"
                : row.status == "6"
                ? "color2"
                : "color3";
            return (
              <div class={statusClass}>
                {getTextByValue(PURCHASE_ORDER_STATUS, row.status)}
              </div>
            );
          },
        },
        // {
        //   field: "",
        //   key: "e",
        //   title: "操作",
        //   width: "",
        //   fixed: "right",
        //   renderBodyCell: ({ row, column, rowIndex }, h) => {
        //     return (
        //       <span>
        //         <van-icon
        //           name="setting-o"
        //           on-click={(e) => this.showSettingPopup(e)}
        //         ></van-icon>
        //       </span>
        //     );
        //   },
        // },
      ],
      tableData: [],
      currentDate: "",
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
      totalPage: 1,
      formData: {},
      key: "",
      showDatePicker: false,
    };
  },
  computed: {
    statusColumns() {
      return PURCHASE_ORDER_STATUS;
    },
  },
  created() {
    this.formData.createStartTime = oneMonthAgoMidnight();
    this.formData.createEndTime = moment()
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    this.onSubmit();
  },
  methods: {
    onStatusSelectConfirm(val) {
      this.formData.status = val.value;
      this.formData.statusText = val.text;
      this.showStatusPicker = false;
    },
    showSettingPopup(e) {
      e.stopPropagation();
      this.showActions = true;
    },
    rowClick({ row, rowIndex, e }) {
      //进入详情页
      this.$router.push({
        path: "/purchase/orderDetail/" + row.id,
      });
    },
    pageChange(pageOptions) {
      this.pageIndex = pageOptions.pageIndex;
      this.pageSize = pageOptions.pageSize;

      this.getList();
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    openDatePicker(key) {
      this.showDatePicker = true;
      this.key = key;
    },
    onConfirm(value) {
      this.formData[this.key] = value;
      this.showDatePicker = false;
    },
    onSubmit() {
      this.pageIndex = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.formData,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      query(params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.datas;
          this.totalCount = res.data.totalCount;
          this.totalPage = res.data.totalPage;
        })
        .catch(() => {
          this.loading = false;
        });
      //
      // let params = {};
      // for (const key in this.formData) {
      //   if (Object.prototype.hasOwnProperty.call(this.formData, key)) {
      //     const value = this.formData[key] === "是" ? true : false;
      //     params[key] = value;
      //   }
      // }
      // update(params).then((res) => {
      //
      // });
    },
  },
};
</script>
<style lang="less" scoped>
* {
  touch-action: pan-y;
}
.van-cell {
  padding: 5px 2px 2px 15px !important;
}
::v-deep .color1 {
  color: #333;
}
::v-deep .color2 {
  color: #ee0a24;
}
::v-deep .color3 {
  color: #07c160;
}
</style>
