<template>
  <div>
    <van-dialog
      v-model="show"
      class="dialog"
      show-cancel-button
      @close="confirm(true)"
      @confirm="confirm"
    >
      <van-form style="position: relative; margin-top: 1rem">
        <van-cell-group inset>
          <van-sticky>
            <van-cell v-if="type !== 'order'">
              <van-field v-model="formData.code" name="code" label="编号" />
            </van-cell>
            <van-cell>
              <van-field v-model="formData.name" name="name" label="名称" />
            </van-cell>
            <van-cell @click="showDialog">
              <van-field
                autocomplete="off"
                v-model="formData.categoryName"
                name="categoryName"
                label="所属类目"
              >
              </van-field>
            </van-cell>
            <van-cell>
              <van-button :loading="loading" type="primary" @click="search"
                >查询</van-button
              >
            </van-cell>
          </van-sticky>
        </van-cell-group>
        <CustomTable
          :maxHeight="maxHeight"
          :scrollWidth="200"
          :columns="type === 'default' ? columns1 : columns2"
          :tableData="data"
          @selectedRowChange="showActive"
          :rowKeyFieldName="
            type === 'retail' || type === 'order' ? 'productId' : 'id'
          "
          :total="totalCount"
          :pageIndex="pageIndex"
          :pageSize="pageSize"
          @pageChange="pageChange"
          ref="customTable"
        ></CustomTable>
        <!-- <van-list
        class="scrollList"
        :finished="true"
        :loading-text="'加载中...'"
        :finished-text="'没有更多了'"
        @load="getProductList"
        :error="true"
        :error-click="getProductList"
        v-if="type == 'default'"
      >
        <van-cell
          ref="cell"
          v-for="item in categoryList"
          :key="item.name"
          :title="item.name"
          @click="showActive(item)"
        />
      </van-list>
      <van-list
        class="scrollList"
        :finished="true"
        :loading-text="'加载中...'"
        :finished-text="'没有更多了'"
        @load="getProductList"
        :error="true"
        :error-click="getProductList"
        v-else
      >
        <van-cell
          ref="cell"
          v-for="item in categoryList"
          :key="item.productName"
          :title="item.productName"
          @click="showActive(item)"
        />
      </van-list> -->
      </van-form>
      <!-- <van-cell style="position: absolute;bottom:0;">
      <van-button round block type="primary" @click="confirm">
        提交
      </van-button>
    </van-cell> -->
    </van-dialog>
    <CategorySelect
      :visible="categorySelectVisible"
      @confirm="categorySelectConfirm"
    ></CategorySelect>
  </div>
</template>
<script>
import { AVAILABLE } from "../utils/constants";
import { loadProduct, reqProduct } from "../service/api/product";
import {
  queryPurchaseProductList,
  loadProductList,
} from "../service/api/purchase/order";
import {
  queryRetailProductList,
  loadRetailProduct,
} from "../service/api/retail/out";
import CustomTable from "./customTable.vue";
import CategorySelect from "./categorySelect.vue";

// 导入样式
export default {
  name: "productSelect",
  components: { CustomTable, CategorySelect },
  props: {
    type: { default: "default", type: String },
    scId: "",
    visible: false,
    columnsType: { default: "radio", type: String },
  },
  data() {
    return {
      tableData: [],
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      totalPage: 1,
      loading: true,
      show: false,
      currentSelectedList: [],
      data: [],
      maxHeight: 400,
      formData: { code: "", name: "" },
      // columns: [
      //   {
      //     label: "商品编号",
      //     prop: "code",
      //   },
      //   {
      //     label: "商品名称",
      //     prop: "name",
      //   },
      //   {
      //     label: "商品类目",
      //     prop: "categoryName",
      //   },
      //   {
      //     label: "商品品牌",
      //     prop: "brandName",
      //   },
      //   {
      //     label: "状态",
      //     prop: "available",
      //   },
      // ],
      columns1: [
        {
          field: this.columnsType,
          key: "b",
          type: this.columnsType,
          title: this.columnsType === "checkbox" ? "多选" : "单选",
          width: 20,
          align: "left",
        },
        { field: "code", key: "code", title: "编号", width: 50 },
        { field: "name", key: "name", title: "名称", width: 50 },
      ],
      columns2: [
        {
          field: this.columnsType,
          key: "b",
          type: this.columnsType,
          title: this.columnsType === "checkbox" ? "多选" : "单选",
          width: 20,
          align: "left",
        },
        { field: "productId", key: "productId", title: "编号", width: 50 },
        { field: "productName", key: "productName", title: "名称", width: 50 },
      ],
      checkboxOption: {
        selectedRowKeys: [],
      },
      radioOption: {
        selectedRowKey: "",
      },
      statusList: AVAILABLE,
      categoryList: [],
      selectedRowKeys: [],
      categorySelectVisible: false,
    };
  },
  watch: {
    visible(newVal, oldVal) {
      this.show = newVal;
    },
    scId: {
      handler(newVal, oldVal) {
        console.log(newVal, this.type);
        if (this.type === "default") {
          this.search();
        } else if (newVal) {
          this.search();
        }
      },
      immediate: true,
    },
  },
  created() {
    // this.search();
    this.maxHeight = window.innerHeight * 0.7 - 200;
    window.addEventListener("resize", () => {
      console.log("resize", window.innerHeight);
      this.maxHeight = window.innerHeight * 0.7 - 200;
    });
  },
  methods: {
    categorySelectConfirm(row) {
      if (!Object.keys(row).length) {
        this.categorySelectVisible = false;
        return;
      }
      this.categorySelectVisible = false;
      this.formData.categoryName = row.name;
      this.formData.categoryId = row.id;
    },
    showDialog() {
      this.categorySelectVisible = true;
    },
    search() {
      this.getProductList();
      // this.categoryList = this.data.filter((el) => {
      //   return el.code.includes(this.formData.code)&&el.name.includes(this.formData.name)
      // });
    },
    pageChange(pageOptions) {
      this.pageIndex = pageOptions.pageIndex;
      this.pageSize = pageOptions.pageSize;
      this.getProductList();
    },
    async showActive(e) {
      console.log(e, "选择表格中");
      if (this.columnsType === "radio") {
        this.currentSelectedList = [e];
        return;
      }
      if (!e || !e.selectedRowKeys.length) return;
      if (e.selectedRowKeys.length === 1) {
        this.currentSelectedList = [e.row];
        return;
      }
      this.selectedRowKeys = e.selectedRowKeys;

      // const exitIndex = this.categoryList.findIndex((i) => {
      //   if (this.type === "order" || this.type === "retail") {
      //     return i.productId === item.productId;
      //   }
      //   return i.id === item.id;
      // });
      // exitIndex != -1
      //   ? this.$refs.cell.forEach((el, index) => {
      //       el.classList.remove("active");
      //       if (index == exitIndex) {
      //         el.classList.add("active");
      //       }
      //     })
      //   : "";
    },
    unselectedAll() {
      this.$refs &&
        this.$refs.customTable &&
        this.$refs.customTable.unselectedAll();
    },
    async confirm(flag = false) {
      if (flag) {
        this.$emit("confirm", []);
        this.unselectedAll();
        return;
      }
      if (this.selectedRowKeys.length) {
        if (this.type === "default") {
          const res = await loadProduct(this.selectedRowKeys);
          this.currentSelectedList = res.data;
        } else if (this.type === "order") {
          const res = await loadProductList({
            ids: this.selectedRowKeys,
            scId: this.scId,
          });
          this.currentSelectedList = res.data;
        } else if (this.type === "retail") {
          const res = await loadRetailProduct({
            ids: this.selectedRowKeys,
            scId: this.scId,
          });
          this.currentSelectedList = res.data;
        }
      }
      this.$emit("confirm", this.currentSelectedList);
      this.unselectedAll();
      this.show = false;
      this.currentSelectedList = [];
      this.formData.categoryId = "";
      this.formData.categoryName = "";
      this.selectedRowKeys = [];
    },
    getProductList() {
      this.loading = true;
      let params = Object.assign({}, this.formData, {
        scId: this.scId,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      });
      try {
        if (this.type === "order") {
          params.condition = params.name;
          queryPurchaseProductList(params)
            .then((res) => {
              this.data = res.data.datas;
              this.categoryList = res.data.datas;
              this.loading = false;
              this.totalCount = res.data.totalCount;
              this.totalPage = res.data.totalPage;
              this.pageSize = res.data.pageSize;
              this.pageIndex = res.data.pageIndex;
            })
            .catch(() => {
              this.loading = false;
            });
        } else if (this.type === "retail") {
          params = {
            condition: this.formData.name,
            isReturn: false,
            scId: this.scId,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
          };
          queryRetailProductList(params)
            .then((res) => {
              this.data = res.data.datas;
              this.categoryList = res.data.datas;
              this.loading = false;
              this.totalCount = res.data.totalCount;
              this.totalPage = res.data.totalPage;
              this.pageSize = res.data.pageSize;
              this.pageIndex = res.data.pageIndex;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          reqProduct(params)
            .then((res) => {
              this.data = res.data.datas;
              this.categoryList = res.data.datas;
              this.loading = false;
              this.totalCount = res.data.totalCount;
              this.totalPage = res.data.totalPage;
              this.pageSize = res.data.pageSize;
              this.pageIndex = res.data.pageIndex;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.active {
  background: #1989fa;
}
.dialog {
  height: 80%;
}
// .van-cell_title {
//   color: #333;
//   font-size: 12px;
// }
::v-deep .van-dialog__content {
  height: 100%;

  .van-form {
    height: 100%;
  }
}

.scrollList {
  height: calc(100% - 200px);
  overflow: auto;
}
::v-deep .van-dialog__footer {
  position: sticky;
  bottom: 0;
}
.van-cell {
  padding: 5px 0px 5px 17px;
}
</style>
