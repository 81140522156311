import Vue from 'vue'
import Router from 'vue-router'

// 引入一级组件
import DashBoard from './../views/dashboard/DashBoard'
// 懒加载
const Home = () => import('./../views/home/Home.vue');
const Category = () => import('./../views/category/Category.vue');
const Product = () => import('./../views/product/index.vue');
const ProductManagement = () => import('./../views/product/management.vue');
const ModifyProduct = () => import('./../views/product/modify.vue');
const AddProduct = () => import('./../views/product/add.vue');
const AdventProduct = () => import('./../views/product/advent.vue');
const ProductSelect = () => import('./../components/productSelect.vue');
const Purchase = () => import('./../views/purchase/index.vue');
const Retail = () => import('./../views/retail/index.vue');
const PurchaseConfig = () => import('./../views/purchase/config/index.vue');
const PurchaseOrder = () => import('./../views/purchase/order/index.vue');
const PurchaseOrderDetail = () => import('./../views/purchase/order/detail.vue');
const PurchaseOrderAdd = () => import('./../views/purchase/order/add.vue');
const PurchaseReceive = () => import('./../views/purchase/receive/index.vue');
const PurchaseReceiveDetail = () => import('./../views/purchase/receive/detail.vue');
const PurchaseReceiveAdd = () => import('./../views/purchase/receive/add.vue');
const PurchaseReturn = () => import('./../views/purchase/return/index.vue');
const PurchaseReturnDetail = () => import('./../views/purchase/return/detail.vue');
const PurchaseReturnAdd = () => import('./../views/purchase/return/add.vue');
const RetailOut = () => import('./../views/retail/out/index.vue');
const RetailOutDetail = () => import('./../views/retail/out/detail.vue');
const RetailOutAdd = () => import('./../views/retail/out/add.vue');
const RetailReturn = () => import('./../views/retail/return/index.vue');
const RetailReturnDetail = () => import('./../views/retail/return/detail.vue');
const RetailReturnAdd = () => import('./../views/retail/return/add.vue');
const RetailConfig = () => import('./../views/retail/config/index.vue');
const Stock = () => import('./../views/stock/index.vue');
const StockProduct = () => import('./../views/stock/product/index.vue');
const StockLog = () => import('./../views/stock/log/index.vue');
const StockLot = () => import('./../views/stock/lot/index.vue');
const Cart = () => import('./../views/product/modify.vue');
const CategorySelect = () => import('../components/stockSelect.vue');
const BrandSelect = () => import('./../components/brandSelect.vue');

// 用户中心
const Mine = () => import('./../views/mine/Mine.vue');
const UserCenter = () => import('./../views/mine/children/UserCenter');
const MineOrder = () => import('./../views/mine/children/MineOrder');
// 引入登录
const Login = () => import('./../views/login/Login.vue')
const qrcodeScan = () => import('../components/qrcodeScan.vue')
import Analysis from './../views/analysis/index.vue'
import AnalysisIndex from './../views/analysis/modules/index.vue'
import AnalysisProduct from './../views/analysis/modules/product.vue'
import AnalysisCategory from './../views/analysis/modules/category.vue'
import AnalysisOrderList from './../views/analysis/modules/orderList.vue'
import AnalysisOrderDetail from './../views/analysis/modules/orderDetail.vue'

Vue.use(Router);

const router = new Router({
    routes: [
        { path: '/', redirect: '/login' },
        {
            path: '/qrcodeScan',
            name: 'qrcodeScan',
            component: qrcodeScan,
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: DashBoard,
            children: [
                { path: '/dashboard', redirect: '/dashboard/home' },
                { path: 'home', name: 'home', component: Home, meta: { keepAlive: true } },
                {
                    path: 'mine',
                    name: 'mine',
                    component: Mine,
                    children: [
                        { path: 'userCenter', component: UserCenter }, // 用户中心
                        { path: 'mineOrder', component: MineOrder }, // 我的订单
                    ]
                }
            ]
        },
        {
            path: '/product',
            name: 'product',
            component: Product,
            children: [
                { path: 'category', name: 'category', component: Category, meta: { title: '商品查询', keepAlive: true } },
                { path: '/product', redirect: '/product/productManagement', meta: { title: '商品管理', keepAlive: true } },
                { path: 'productManagement', name: 'productManagement', meta: { title: '商品管理', keepAlive: true }, component: ProductManagement },
                {
                    path: 'modify/:id',
                    name: 'modify', meta: { title: '商品修改', keepAlive: true },
                    component: ModifyProduct,
                },
                {
                    path: 'add',
                    name: 'add', meta: { title: '商品新增', keepAlive: true },
                    component: AddProduct,
                },
                {
                    path: 'advent',
                    name: 'advent', meta: { title: '临期预警', keepAlive: true },
                    component: AdventProduct,
                },
                {
                    path: 'categorySelect',
                    name: 'categorySelect', meta: { title: '选择类目', keepAlive: true },
                    component: CategorySelect,
                },
                {
                    path: 'productSelect',
                    name: 'productSelect', meta: { title: '选择商品', keepAlive: true },
                    component: ProductSelect,
                },
                {
                    path: 'brandSelect',
                    name: 'brandSelect', meta: { title: '选择品牌', keepAlive: true },
                    component: BrandSelect,
                },
            ]
        },
        {
            path: '/purchase',
            name: 'purchase',
            component: Purchase,
            children: [
                { path: '/purchase', redirect: '/purchase/config', meta: { title: '采购管理', keepAlive: true } },
                {
                    path: 'config',
                    name: 'config', meta: { title: '采购参数设置', keepAlive: true, prev: '/dashboard/home' },
                    component: PurchaseConfig,
                },
                {
                    path: 'order',
                    name: 'order', meta: { title: '采购订单管理', keepAlive: true, prev: '/dashboard/home' },
                    component: PurchaseOrder,

                },
                {
                    path: 'orderDetail/:id',
                    name: 'orderDetail', meta: { title: '采购订单详情', keepAlive: true, prev: '/purchase/order' },
                    component: PurchaseOrderDetail,
                },
                {
                    path: 'orderAdd',
                    name: 'orderAdd', meta: { title: '新增采购订单', keepAlive: true, prev: '/purchase/order' },
                    component: PurchaseOrderAdd,
                },
                {
                    path: 'receive',
                    name: 'receive', meta: { title: '采购收货管理', keepAlive: true, prev: '/dashboard/home' },
                    component: PurchaseReceive,

                },
                {
                    path: 'receiveDetail/:id',
                    name: 'receiveDetail', meta: { title: '采购收货详情', keepAlive: true, prev: '/purchase/receive' },
                    component: PurchaseReceiveDetail,
                },
                {
                    path: 'receiveAdd',
                    name: 'receiveAdd', meta: { title: '新增采购收货', keepAlive: true, prev: '/purchase/receive' },
                    component: PurchaseReceiveAdd,
                },
                {
                    path: 'return',
                    name: 'return', meta: { title: '采购退货管理', keepAlive: true, prev: '/dashboard/home' },
                    component: PurchaseReturn,

                },
                {
                    path: 'returnDetail/:id',
                    name: 'returnDetail', meta: { title: '采购退货详情', keepAlive: true, prev: '/purchase/return' },
                    component: PurchaseReturnDetail,
                },
                {
                    path: 'returnAdd',
                    name: 'returnAdd', meta: { title: '新增采购退货', keepAlive: true, prev: '/purchase/return' },
                    component: PurchaseReturnAdd,
                },
            ]
        },
        {
            path: '/retail',
            name: 'retail',
            component: Retail,
            children: [
                { path: '/retail', redirect: '/retail/config', meta: { title: '零售管理', keepAlive: true } },
                {
                    path: 'config',
                    name: 'config', meta: { title: '零售参数设置', keepAlive: true },
                    component: RetailConfig,
                },
                {
                    path: 'out',
                    name: 'out', meta: { title: '零售出库管理', keepAlive: true, prev: '/dashboard/home' },
                    component: RetailOut,

                },
                {
                    path: 'outDetail/:id',
                    name: 'outDetail', meta: { title: '零售出库详情', keepAlive: true, prev: '/retail/out' },
                    component: RetailOutDetail,
                },
                {
                    path: 'outAdd',
                    name: 'outAdd', meta: { title: '新增零售出库', keepAlive: true, prev: '/retail/out' },
                    component: RetailOutAdd,
                },
                {
                    path: 'return',
                    name: 'return', meta: { title: '零售退货管理', keepAlive: true, prev: '/dashboard/home' },
                    component: RetailReturn,

                },
                {
                    path: 'returnDetail/:id',
                    name: 'returnDetail', meta: { title: '零售退货详情', keepAlive: true, prev: '/retail/return' },
                    component: RetailReturnDetail,
                },
                {
                    path: 'returnAdd',
                    name: 'returnAdd', meta: { title: '新增零售退货', keepAlive: true, prev: '/retail/return' },
                    component: RetailReturnAdd,
                },
            ]
        },
        {
            path: '/stock',
            name: 'stock',
            component: Stock,
            children: [
                { path: '/stock', redirect: '/stock/product', meta: { title: '商品库存管理', keepAlive: true } },
                {
                    path: 'product',
                    name: 'product', meta: { title: '商品库存', keepAlive: true, prev: '/dashboard/home' },
                    component: StockProduct,
                },
                {
                    path: 'product/log',
                    name: 'product/log', meta: { title: '库存变动记录', keepAlive: true, prev: '/dashboard/home' },
                    component: StockLog,

                },
                {
                    path: 'lot',
                    name: 'lot', meta: { title: '商品批次', keepAlive: true, prev: '/dashboard/home' },
                    component: StockLot,

                },

            ]
        },
        {
            path: '/analysis',
            name: 'analysis',
            component: Analysis,
            children: [
                { path: '/analysis', redirect: '/analysis/index', meta: { title: '商品分析', keepAlive: true } },
                {
                    path: 'index',
                    name: 'index', meta: { title: '商品分析', keepAlive: true, prev: '/dashboard/home' },
                    component: AnalysisIndex,
                },
                {
                    path: 'product',
                    name: 'product', meta: { title: '商品排行', keepAlive: true, prev: '/analysis' },
                    component: AnalysisProduct,
                },
                {
                    path: 'category',
                    name: 'category', meta: { title: '品类排行', keepAlive: true, prev: '/analysis' },
                    component: AnalysisCategory,
                },
                {
                    path: 'orderList/:id',
                    name: 'orderList', meta: { title: '商品订单列表', keepAlive: true },
                    component: AnalysisOrderList,

                },
                {
                    path: 'orderDetail/:id',
                    name: 'orderDetail', meta: { title: '商品订单明细', keepAlive: true, prev: '/analysis/orderList' },
                    component: AnalysisOrderDetail,

                },
            ]
        },
        // {
        //     path: '/confirmOrder',
        //     name: 'confirmOrder',
        //     component: Order,
        //     children: [
        //         {
        //             path: 'myAddress',
        //             name: 'myAddress',
        //             component: MyAddress,
        //             children: [
        //                 { path: 'addAddress', name: 'addAddress', component: AddAddress },
        //                 { path: 'editAddress', name: 'editAddress', component: EditAddress }
        //             ]
        //         }, {
        //             path: 'orderDetail',
        //             name: 'orderDetail',
        //             component: OrderDetail
        //         }
        //     ]
        // },
        {
            path: '/login',
            name: 'login',
            component: Login
        }
    ]
})
router.beforeEach(async (to, from, next) => {
    if (to.path === '/login') {
        if (localStorage.getItem('token')) {
            next('/dashboard')
            return
        }
        next()
        return
    }
    if (!localStorage.getItem('token')) {
        next('/login')
        return
    }
    next()
})
export default router;

