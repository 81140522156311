<template>
  <div>
    <header class="pt10">
      <van-field
        v-model="params.searchStr"
        name="searchStr"
        label=""
        placeholder="请输入品类名称搜索"
      >
        <template #button> <van-icon name="search" @click="search" /> </template
      ></van-field>
      <DateChange ref="dateChange" @change="handlerDateChange" />
    </header>
    <div class="p15 bg-white">
      <div class="p10">
        <div>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div
              v-for="(item, i) in categoryList"
              class="item"
              @click="toOrderList(item)"
            >
              <div class="flex flex-start">
                <div class="w20">
                  <van-icon v-if="i < 3" name="award" :color="color[i]" />
                  <span v-else class="mainColor fb">{{ i + 1 }}</span>
                </div>
                <span class="fs16 p5">{{ item.categoryName }}</span>
              </div>
              <div class="flex flex-between fs14 pt5">
                <div>销售总额</div>
                <div class="fb grey-8 fs16">{{ item.totalAmount }}</div>
              </div>
              <div class="flex flex-between fs14 pt5">
                <div>销售单数</div>
                <div class="fb grey-8 fs16">{{ item.orderCnt }}</div>
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateChange from "@/components/dateChange.vue";
import { categoryByPage } from "../../../service/api/home";

export default {
  name: "index",
  components: { DateChange },
  data() {
    return {
      loading: false,
      finished: false,
      categoryList: [],
      color: ["red", "orange", "orange-light"],
      params: {
        startDate: "",
        endDate: "",
        searchStr: "",
      },
      totalCount: 0,
      totalPage: 0,
      pageIndex: 0,
      pageSize: 20,
    };
  },
  mounted() {
    this.params.startDate = this.$refs.dateChange.formattedStartDate;
    this.params.endDate = this.$refs.dateChange.formattedEndDate;
  },
  methods: {
    search() {
      this.pageIndex = 1;
      this.getList();
    },
    toOrderList(item) {
      this.$router.push({
        path: "/analysis/product",
        query: { id: item.categoryId, name: item.categoryName },
      });
    },
    getList(flag = false) {
      try {
        const params = {
          ...this.params,
          productId: this.id,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
        };
        categoryByPage(params).then((res) => {
          this.totalCount = res.data.totalCount;
          this.totalPage = res.data.totalPage;
          this.pageIndex = res.data.pageIndex;

          if (flag) {
            this.categoryList.push(...res.data.datas);
            this.loading = false;
          } else {
            this.categoryList = res.data.datas;
          }
          if (
            this.pageIndex !== 0 &&
            (this.pageIndex >= this.totalPage || this.totalCount === 0)
          ) {
            this.finished = true;
          }
        });
      } catch (error) {
        console.log(error);
        this.finished = true;
      }
    },
    handlerDateChange(startDate, endDate) {
      this.pageIndex = 1;
      this.finished = false;
      this.params = { ...this.params, startDate, endDate };
      this.getList();
    },
    onLoad() {
      if (
        this.pageIndex !== 0 &&
        (this.pageIndex >= this.totalPage || this.totalCount === 0)
      ) {
        this.finished = true;
        return;
      }
      this.pageIndex++;
      this.getList(true);
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
}
.van-cell,
.van-field {
  background-color: #4c9673;
  ::v-deep .van-field__body {
    background-color: #fff;
  }
}
::v-deep .van-field__button {
  font-size: 20px;
  padding-right: 5px;
}
</style>
