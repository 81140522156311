import requests from '../request'
// 获取当天销售总额：/salesTotal
// 按分类获取销售额：/salesChartByCategory
// /chart/product/salesChart
// 
// /chart/retail/index
export const retail = (data) => {
    return requests({
        url: '/chart/retail/index',
        method: 'post',
        data
    })
}
// /chart/product/singleProduct
export const singleProduct = (data) => {
    return requests({
        url: '/chart/product/singleProduct',
        method: 'post',
        data
    })
}
// /chart/product/singleProductOrderList
export const singleProductOrderList = (data) => {
    return requests({
        url: '/chart/product/singleProductOrderList',
        method: 'post',
        data
    })
}
// /chart/product/sortByPage
export const sortByPage = (data) => {
    return requests({
        url: '/chart/product/sortByPage',
        method: 'post',
        data
    })
}
// sortCategoryByPage

export const categoryByPage = (data) => {
    return requests({
        url: '/chart/product/sortCategoryByPage',
        method: 'post',
        data
    })
}
export const salesChart = (params) => {
    return requests({
        url: '/chart/product/salesChart',
        method: 'get',
        params
    })
}
export const salesChartByCategory = (params) => {
    return requests({
        url: '/chart/product/salesChartByCategory',
        method: 'get',
        params
    })
}
export const salesTotal = (params) => {
    return requests({
        url: '/chart/product/salesTotal',
        method: 'get',
        params
    })
}