<template>
  <div class="flex flex-between pl10 pr10">
    <h4>
      {{ userName }}
    </h4>
    <div @click="showPopover = true" style="z-index: 2000">
      <van-popover v-model:show="showPopover">
        <template #reference>
          <van-icon name="ellipsis" />
        </template>
        <div @click="toLogout" class="w80 p5 fs14">退出登录</div>
      </van-popover>
    </div>
  </div>
</template>
<script>
import { logout } from "../../service/api/product";
import { Dialog } from "vant";
export default {
  name: "index",
  data() {
    return { userName: "", userInfo: "", showPopover: false };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userName = this.userInfo.merchName || this.userInfo?.user?.name;
  },
  methods: {
    toLogout() {
      Dialog.confirm({
        title: "退出登录",
        message: "确认退出登录？",
      })
        .then(async () => {
          await logout();
          localStorage.setItem("token", "");
          localStorage.setItem("userInfo", "");
          localStorage.setItem("scanText ", "");
          sessionStorage.clear();
          this.$router.push("/login");
        })
        .catch(() => {
          // localStorage.setItem("token", "");
          // localStorage.setItem("userInfo", "");
          // localStorage.setItem("scanText ", "");
          // sessionStorage.clear();
          // this.$router.push("/login");
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
