<template>
  <div id="orderDetail">
    <Loading v-if="loading"></Loading>
    <van-form>
      <van-cell-group inset>
        <van-cell @click="showStockSelect">
          <van-field
            autocomplete="off"
            v-model="form.scName"
            name="scName"
            required
            label="仓库"
            :readonly="curType === 'detail'"
        /></van-cell>
        <!-- <van-cell @click="showSupplierSelect"> -->
        <!-- 暂时未做会员选择弹窗 -->
        <van-cell v-if="retailConfig.retailOutSheetRequireMember">
          <van-field
            autocomplete="off"
            :required="retailConfig.retailOutSheetRequireMember"
            v-model="form.retailOutSheetRequireMember"
            name="retailOutSheetRequireMember"
            label="会员"
            :readonly="curType === 'detail'"
        /></van-cell>
        <!-- 暂时未做销售员选择弹窗 -->
        <!-- <van-cell>
          <van-field
            autocomplete="off"
            v-model="form.salerId"
            name="salerId"
            label="销售员"
            :readonly="curType === 'detail'"
        /></van-cell> -->
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="form.paymentDate"
            name="paymentDate"
            required
            label="付款日期"
            :readonly="curType === 'detail'"
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="form.status"
            name="status"
            label="状态"
            readonly
            :formatter="formatter"
          ></van-field
        ></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="form.refuseReason"
            name="refuseReason"
            label="拒绝理由"
            disabled
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="form.createBy"
            name="createBy"
            label="操作人"
            disabled
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            label="操作时间"
            name="createTime"
            v-model:value="form.createTime"
            disabled
          >
          </van-field
        ></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            label="审核人"
            name="approveBy"
            disabled
            v-model:value="form.approveBy"
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            label="审核时间"
            name="approveTime"
            v-model:value="form.approveTime"
            disabled
          >
          </van-field
        ></van-cell>
      </van-cell-group>
      <van-form style="margin: 16px">
        <div v-if="curType != 'detail'" class="action">
          <Van-icon
            name="add-o"
            type="primary"
            @click="showProductSelect = true"
          ></Van-icon>
        </div>
        <CustomTable
          :scrollWidth="600"
          :showPagination="false"
          :columns="columns"
          :tableData="tableData"
        ></CustomTable>
      </van-form>

      <div style="margin: 16px">
        <!-- // ordertimeline -->
        <OrderTimeLine :id="id"></OrderTimeLine>
        <van-form>
          <van-cell>合计</van-cell>
          <van-cell-group>
            <van-cell
              ><van-field
                autocomplete="off"
                label="出库数量"
                readonly
                v-model="form.totalNum"
                type="number"
            /></van-cell>
            <van-cell
              ><van-field
                autocomplete="off"
                label="赠品数量"
                readonly
                type="number"
                v-model="form.giftNum"
            /></van-cell>
            <van-cell
              ><van-field
                autocomplete="off"
                label="含税总金额"
                readonly
                type="number"
                v-model="form.totalAmount"
            /></van-cell>
          </van-cell-group>
        </van-form>
        <!-- 约定支付 -->
        <PayType
          ref="payType"
          :type="curType"
          style="margin: 10px 0"
          :list="tableData2"
        ></PayType>
        <!-- 备注 -->
        <van-field
          autocomplete="off"
          v-model="form.description"
          disabled
          rows="2"
          autosize
          label="备注"
          type="textarea"
        />
        <van-cell class="actions-btn">
          <van-button
            type="primary"
            @click="toAction('approvePass')"
            round
            v-if="(status == 0 || status == 6) && curType !== 'modify'"
            v-permission="'retail:out:approve'"
            >审核通过</van-button
          >
          <van-button
            type="primary"
            @click="toAction('approveRefuse')"
            round
            v-if="(status == 0 || status == 6) && curType !== 'modify'"
            v-permission="'retail:out:approve'"
            >审核拒绝</van-button
          >
          <van-button
            type="primary"
            @click="toAction('modify')"
            round
            v-if="(status == 0 || status == 6) && curType !== 'modify'"
            v-permission="'retail:out:modify'"
            >修改</van-button
          >
          <van-button
            type="warning"
            @click="toAction('del')"
            round
            v-if="(status == 0 || status == 6) && curType !== 'modify'"
            v-permission="'retail:out:delete'"
            >删除</van-button
          >
          <van-button
            type="primary"
            @click="toAction('confirm')"
            round
            block
            v-if="(status == 0 || status == 6) && curType === 'modify'"
            >保存</van-button
          >
          <van-button @click="toAction('close')" type="info" round block
            >关闭</van-button
          >
        </van-cell>
      </div>
    </van-form>
    <van-dialog
      v-model="approveRefuseVisible"
      title="确认弹窗"
      show-cancel-button
      @cancel="cancelApproveRefuse"
      @confirm="confirmApproveRefuse"
    >
      <van-cell title="确认进行审批拒绝操作？"></van-cell>

      <van-cell v-model="refuseReason">
        <van-field
          autocomplete="off"
          v-model="refuseReason"
          rows="2"
          autosize
          type="textarea"
          label="拒绝理由"
        ></van-field>
      </van-cell>
    </van-dialog>
    <ProductSelect
      type="retail"
      columnsType="checkbox"
      :scId="this.form.scId"
      :visible="showProductSelect"
      @confirm="onSelectProductConfirm"
    ></ProductSelect>
    <DateTimePicker
      value-format="yyyy-MM-dd"
      :showPicker="showDatePicker"
      @confirm="onDatePickerConfirm"
      @cancel="showDatePicker = false"
    ></DateTimePicker>
    <StockSelect
      :visible="stockSelectVisible"
      @confirm="stockSelectConfirm"
    ></StockSelect>
    <SupplierSelect
      :visible="supplierSelectVisible"
      @confirm="supplierSelectConfirm"
    ></SupplierSelect>
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
import Loading from "../../../components/loading.vue";

import CustomTable from "../../../components/customTable.vue";
import OrderTimeLine from "../../../components/orderTimeLine.vue";
import ProductSelect from "../../../components/productSelect.vue";
import DateTimePicker from "../../../components/dateTimePicker.vue";
import StockSelect from "../../../components/stockSelect.vue";
import SupplierSelect from "../../../components/supplierSelect..vue";
import PayType from "../../../components/payType.vue";
import {
  getTextByValue,
  PURCHASE_ORDER_STATUS,
} from "../../../utils/constants";
import {
  get,
  update,
  deleteById,
  approvePass,
  approveRefuse,
} from "../../../service/api/retail/out";
import { getOrderTimeLine } from "../../../service/api";
import * as configApi from "../../../service/api/retail/config";
import moment from "moment";

export default {
  name: "index",
  components: {
    CustomTable,
    OrderTimeLine,
    ProductSelect,
    DateTimePicker,
    PayType,
    Loading,
    StockSelect,
    SupplierSelect,
  },
  data() {
    return {
      supplierSelectVisible: false,
      stockSelectVisible: false,
      loading: true,
      showProductSelect: false,
      showPayTypeSelect: false,
      refuseReason: "",
      approveRefuseVisible: false,
      status: 0,
      curType: "detail",
      brandSelectVisible: false,
      id: this.$route.params.id,
      showDatePicker: false,
      retailConfig: {},
      statusList: [
        { text: "启用", value: "1" },
        { text: "停用", value: "0" },
      ],
      tableData2: [],
      columns: [
        {
          field: "productName",
          key: "productName",
          title: "商品名称",
          width: 100,
          fixed: "left",
        },
        {
          field: "productCode",
          key: "productCode",
          title: "商品编号",
          width: 80,
        },

        { field: "unit", key: "unit", title: "单位", width: 80 },
        { field: "spec", key: "spec", title: "规格", width: 80 },
        {
          field: "categoryName",
          key: "categoryName",
          title: "商品类目",
          width: 80,
        },
        { field: "brandName", key: "brandName", title: "商品品牌", width: 80 },
        {
          field: "retailPrice",
          key: "retailPrice",
          title: "参考零售价（元）",
          align: "right",
          width: 100,
        },
        {
          field: "isGift",
          key: "isGift",
          title: "是否赠品",
          width: 70,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field] ? "是" : "否";
            return <span>{text}</span>;
          },
        },
        {
          field: "taxAmount",
          key: "taxAmount",
          title: "含税金额",
          width: 60,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text =
              this.$utils.isFloatGeZero(row.taxPrice) &&
              this.$utils.isIntegerGeZero(row.outNum)
                ? this.$utils.mul(row.taxPrice, row.outNum)
                : "";
            return <span>{text}</span>;
          },
        },
        { field: "stockNum", key: "stockNum", title: "库存数量", width: 60 },
        {
          field: "orderNum",
          key: "orderNum",
          title: "零售数量",
          align: "right",
          width: 100,
        },
        {
          field: "discountRate",
          key: "discountRate",
          title: "折扣（%）",
          align: "right",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            const text = row[column.field];
            return flag ? (
              <span>{text}</span>
            ) : (
              <van-field
                autocomplete="off"
                name="taxPrice"
                value={text}
                type="number"
                on-input={(val) =>
                  this.changeDiscountRate(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "taxPrice",
          key: "taxPrice",
          title: "价格（元）",
          align: "right",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            const text = row[column.field];
            return flag ? (
              <span>{text}</span>
            ) : (
              <van-field
                autocomplete="off"
                name="taxPrice"
                value={text}
                type="number"
                on-input={(val) =>
                  this.taxPriceInput(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },

        {
          field: "outNum",
          key: "outNum",
          title: "出库数量",
          align: "right",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            const text = row[column.field];
            return flag ? (
              <span>{text}</span>
            ) : (
              <van-field
                autocomplete="off"
                name="taxPrice"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "taxRate",
          key: "taxRate",
          title: "税率（%）",
          align: "right",
          width: 80,
        },
        {
          field: "description",
          key: "description",
          title: "备注",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            const text = row[column.field];
            return flag ? (
              <span>{text}</span>
            ) : (
              <van-field
                autocomplete="off"
                name="description"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "",
          key: "e",
          title: "操作",
          width: "",
          center: "left",
          fixed: "right",
          width: 50,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            return flag ? (
              ""
            ) : (
              <span class="action">
                <van-icon
                  name="delete-o"
                  on-click={() => this.deleteRow(rowIndex)}
                ></van-icon>
              </span>
            );
          },
        },
      ],
      tableData: [],
      form: {
        id: "",
        name: "",
        retailPrice: "",
        categoryName: "",
        unit: "",
        externalCode: "",
      },
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
      index: 0,
    };
  },
  async mounted() {
    debugger;
    try {
      this.loading = true;
      const res = await get(this.id);

      const data = res.data;
      this.form = Object.assign(this.form, {
        scId: data.scId,
        scName: data.scName,
        memberId: data.memberId,
        salerId: data.salerId || "",
        paymentDate: data.paymentDate || "",
        description: data.description,
        status: data.status,
        createBy: data.createBy,
        createTime: data.createTime,
        approveBy: data.approveBy,
        approveTime: data.approveTime,
        refuseReason: data.refuseReason,
        totalNum: 0,
        giftNum: 0,
        totalAmount: 0,
        allowModifyPaymentDate: true,
      });

      const tableData = data.details || [];
      tableData.forEach((item) => {
        item.isFixed = false;

        return item;
      });
      this.tableData = tableData.map((item) =>
        Object.assign(this.emptyProduct(), item)
      );

      this.memberChange(this.form.memberId, true);

      this.tableData2 = data.payTypes || [];
      this.calcSum();
      const orderTimeRes = await getOrderTimeLine({ orderId: this.id });
      configApi.get().then((res) => {
        this.retailConfig = res.data;
      });
    } catch (error) {
      console.log(error);
      this.$router.push("/retail/out");
      this.loading = false;
    } finally {
      this.loading = false;
    }
  },
  watch: {
    tableData: {
      handler(newVal, oldVal) {
        this.calcSum();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    emptyProduct() {
      return {
        id: this.$utils.uuid(),
        productId: "",
        productCode: "",
        productName: "",
        skuCode: "",
        externalCode: "",
        unit: "",
        spec: "",
        categoryName: "",
        brandName: "",
        retailPrice: "",
        taxPrice: "",
        discountRate: 100,
        stockNum: "",
        orderNum: "",
        outNum: "",
        taxRate: "",
        isGift: false,
        taxAmount: "",
        description: "",
        isFixed: false,
        products: [],
      };
    },
    memberChange(memberId, unModify) {
      if (!this.$utils.isEmpty(memberId)) {
        api.getPaymentDate(memberId).then((res) => {
          if (!unModify) {
            if (res.allowModify) {
              // 如果允许修改付款日期
              if (this.$utils.isEmpty(this.form.paymentDate)) {
                this.form.paymentDate = res.paymentDate || "";
              }
            } else {
              // 不允许修改则按默认日期
              this.form.paymentDate = res.paymentDate || "";
            }
          }
          this.form.allowModifyPaymentDate = res.allowModify;
        });
      }
    },
    changeDiscountRate(index, key, value) {
      this.inputChange(index, key, value);
      const row = this.tableData[index];
      if (
        this.$utils.isFloatGeZero(row.discountRate) &&
        this.$utils.isFloatGtZero(row.retailPrice)
      ) {
        row.taxPrice = this.$utils
          .div(this.$utils.mul(row.retailPrice, row.discountRate), 100)
          .toFixed(2);
      }
    },
    taxPriceInput(index, key, value) {
      this.inputChange(index, key, value);
      const row = this.tableData[index];
      if (row.retailPrice !== 0) {
        if (this.$utils.isFloatGeZero(row.taxPrice)) {
          row.discountRate = this.$utils
            .mul(this.$utils.div(row.taxPrice, row.retailPrice), 100)
            .toFixed(2);
        }
      }
    },
    genParams() {
      const params = {
        id: this.id,
        scId: this.form.scId,
        memberId: this.form.memberId,
        salerId: this.form.salerId || "",
        paymentDate: this.form.paymentDate || "",
        allowModifyPaymentDate: true,
        description: this.form.description,
        payTypes: this.$refs.payType.getTableData().map((t) => {
          return {
            id: t.payTypeId,
            payAmount: t.payAmount,
            text: t.text,
          };
        }),
        products: this.tableData
          .filter((t) => this.$utils.isIntegerGtZero(t.outNum))
          .map((t) => {
            const product = {
              productId: t.productId,
              oriPrice: t.retailPrice,
              taxPrice: t.taxPrice,
              discountRate: t.discountRate,
              orderNum: t.outNum,
              description: t.description,
            };

            return product;
          }),
      };

      return params;
    },

    addDays(date, days) {
      if (!date || !days) {
        return "";
      }
      const result = new Date(date);
      result.setDate(result.getDate() + Number(days));
      return moment(result).format("YYYY-MM-DD");
    },
    showStockSelect() {
      if (this.curType != "detail") this.stockSelectVisible = true;
    },
    stockSelectConfirm(row) {
      if (Object.keys(row).length === 0) {
        this.stockSelectVisible = false;
        return;
      }
      this.stockSelectVisible = false;
      this.form.scName = row.name;
      this.form.scId = row.id;
    },
    showSupplierSelect() {
      if (this.curType != "detail") this.supplierSelectVisible = true;
    },
    supplierSelectConfirm(row) {
      if (Object.keys(row).length === 0) {
        this.supplierSelectVisible = false;
        return;
      }
      this.supplierSelectVisible = false;
      this.form.retailOutSheetRequireMember = row.name;
      this.form.supplierId = row.id;
    },
    inputChange(rowIndex, field, val) {
      this.$set(this.tableData, rowIndex, {
        ...this.tableData[rowIndex],
        [field]: val,
      });
    },
    onDatePickerConfirm(val) {
      val
        ? this.$set(this.tableData, this.index, {
            ...this.tableData[this.index],
            productionDate: val,
          })
        : "";
      this.showDatePicker = false;
      this.index = 0;
    },
    openDateTimePicker(index) {
      this.index = index;
      this.showDatePicker = true;
    },
    // 校验数据
    validData() {
      if (this.$utils.isEmpty(this.form.scId)) {
        this.$utils.createError("仓库不允许为空！");
        return false;
      }

      if (
        this.retailConfig.retailOutSheetRequireMember &&
        this.$utils.isEmpty(this.form.memberId)
      ) {
        this.$utils.createError("会员不允许为空！");
        return false;
      }

      if (this.form.allowModifyPaymentDate) {
        if (this.$utils.isEmpty(this.form.paymentDate)) {
          this.$utils.createError("付款日期不允许为空！");
          return false;
        }
      }

      if (this.$utils.isEmpty(this.tableData)) {
        this.$utils.createError("请录入商品！");
        return false;
      }

      for (let i = 0; i < this.tableData.length; i++) {
        const product = this.tableData[i];

        if (this.$utils.isEmpty(product.productId)) {
          this.$utils.createError("第" + (i + 1) + "行商品不允许为空！");
          return false;
        }

        if (this.$utils.isEmpty(product.taxPrice)) {
          this.$utils.createError("第" + (i + 1) + "行商品价格不允许为空！");
          return false;
        }

        if (!this.$utils.isFloat(product.taxPrice)) {
          this.$utils.createError("第" + (i + 1) + "行商品价格必须为数字！");
          return false;
        }

        if (product.isGift) {
          if (parseFloat(product.taxPrice) !== 0) {
            this.$utils.createError("第" + (i + 1) + "行商品价格必须等于0！");
            return false;
          }
        } else {
          if (!this.$utils.isFloatGtZero(product.taxPrice)) {
            this.$utils.createError("第" + (i + 1) + "行商品价格必须大于0！");
            return false;
          }
        }

        if (!this.$utils.isNumberPrecision(product.taxPrice, 2)) {
          this.$utils.createError(
            "第" + (i + 1) + "行商品价格最多允许2位小数！"
          );
          return false;
        }

        if (!this.$utils.isEmpty(product.outNum)) {
          if (!this.$utils.isInteger(product.outNum)) {
            this.$utils.createError(
              "第" + (i + 1) + "行商品出库数量必须为整数！"
            );
            return false;
          }

          if (product.isFixed) {
            if (!this.$utils.isIntegerGeZero(product.outNum)) {
              this.$utils.createError(
                "第" + (i + 1) + "行商品出库数量不允许小于0！"
              );
              return false;
            }
          } else {
            if (!this.$utils.isIntegerGtZero(product.outNum)) {
              this.$utils.createError(
                "第" + (i + 1) + "行商品出库数量必须大于0！"
              );
              return false;
            }
          }
        } else {
          if (!product.isFixed) {
            this.$utils.createError(
              "第" + (i + 1) + "行商品出库数量不允许为空！"
            );
            return false;
          }
        }
      }

      if (!this.$refs.payType.validData()) {
        return false;
      }

      const payTypes = this.$refs.payType.getTableData();
      const totalPayAmount = payTypes.reduce(
        (tot, item) => this.$utils.add(tot, item.payAmount),
        0
      );
      if (!this.$utils.eq(this.form.totalAmount, totalPayAmount)) {
        this.$utils.createError(
          "所有支付方式的支付金额不等于含税总金额，请检查！"
        );
        return false;
      }

      return true;
    },
    // 计算汇总数据
    calcSum() {
      let totalNum = 0;
      let giftNum = 0;
      let totalAmount = 0;

      this.tableData
        .filter((t) => {
          return (
            this.$utils.isFloatGeZero(t.taxPrice) &&
            this.$utils.isIntegerGeZero(t.outNum)
          );
        })
        .forEach((t) => {
          const num = parseInt(t.outNum);
          if (t.isGift) {
            giftNum = this.$utils.add(giftNum, num);
          } else {
            totalNum = this.$utils.add(totalNum, num);
          }

          totalAmount = this.$utils.add(
            totalAmount,
            this.$utils.mul(num, t.taxPrice)
          );
        });

      this.form.totalNum = totalNum;
      this.form.giftNum = giftNum;
      this.form.totalAmount = totalAmount;
    },
    onSelectProductConfirm(row) {
      if (this.showProductSelect) {
        this.showProductSelect = false;
      }
      if (row.length) {
        row.forEach((el) => {
          this.tableData.push({
            ...el,
          });
        });
      }
    },
    deleteRow(index) {
      this.tableData.splice(index, 1);
    },
    confirmApproveRefuse() {
      approveRefuse({
        id: this.id,
        refuseReason: this.refuseReason,
      }).then((res) => {
        Toast.success(res.msg);
        this.$router.push({ path: "/retail/out" });
      });
    },
    cancelApproveRefuse() {
      this.approveRefuseVisible = false;
      this.refuseReason = "";
    },

    formatter(val) {
      return isNaN(val) ? val : getTextByValue(PURCHASE_ORDER_STATUS, val);
    },
    async toAction(action) {
      let promise = null;
      if (action === "close") {
        if (this.curType === "modify") {
          this.curType = "detail";
          return;
        }
        this.$router.push({ path: "/retail/out" });
      } else if (action === "modify") {
        this.curType = "modify";
        return;
      } else if (action === "confirm") {
        //修改校验  todo
        if (!this.validData()) return;
        const params = this.genParams();
        update(params).then((res) => {
          Toast.success(res.msg);
          this.$router.push({ path: "/retail/out" });
        });
      } else if (action === "del") {
        Dialog.confirm({
          title: "确认弹窗",
          message: "确定要删除该采购订单吗",
        })
          .then(async () => {
            promise = await deleteById({ id: this.id });
            Toast.success(promise.msg);
            this.$router.push({ path: "/retail/out" });
          })
          .catch(() => {
            Toast.fail(promise.msg);
          });
      } else if (action === "approvePass") {
        Dialog.confirm({
          title: "确认弹窗",
          message: "确定要进行审批通过操作",
        })
          .then(async () => {
            promise = await approvePass({
              id: this.id,
            });
            Toast.success(promise.msg);
            this.$router.push({ path: "/retail/out" });
          })
          .catch(() => {
            Toast.fail(promise.msg);
          });
      } else if (action === "approveRefuse") {
        this.approveRefuseVisible = true;
      }
    },
  },
};
</script>
<style scoped>
.van-cell {
  padding: 5px 0px 5px 17px;
}
#orderDetail {
  position: relative;
}
::v-deep .van-field__body {
  height: 100%;
}
.actions-btn {
  .van-button {
    margin: 5px 5px 0 0;
  }
}
.action {
  background-color: #fff;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 26px;
  height: 36px;
}
</style>
