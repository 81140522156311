<template>
  <div id="home">
    <header>
      <headerIndex />
      <DateChange
        ref="dateChange"
        :showDate="false"
        @change="handlerDateChange"
      />
      <div class="flex flex-between pl10 pr10">
        <div class="gray-2">营业额</div>
        <div class="fs26">{{ totalRetailAmount }}</div>
      </div>
    </header>
    <div class="bg-white">
      <van-grid square :column-num="3">
        <van-grid-item>
          <div class="fb fs14 gray-7">客单价</div>
          <div class="fb">{{ price }}</div>
        </van-grid-item>
        <van-grid-item>
          <div class="fb fs14 gray-7">客单数</div>
          <div class="fb">{{ totalCount }}</div>
        </van-grid-item>
        <van-grid-item>
          <div class="fb fs14 gray-7">收款金额</div>
          <div class="fb">{{ receiveAmount }}</div>
        </van-grid-item>
        <van-grid-item v-for="item in paymentTypes" :key="item.payTypeId">
          <div class="fb fs14 gray-7">{{ item.payTypeName }}</div>
          <div class="fb">{{ item.payAmount }}</div>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="m15 bg-white">
      <div class="content">
        <div class="title">常用应用</div>
        <van-row type="flex" justify="space-around">
          <van-col
            span="6"
            class="item flex flex-column"
            @click="goTo('/product')"
          >
            <div class="item2">
              <van-icon name="gift-o"></van-icon>
              <div class="fs12 gray-8">商品管理</div>
            </div>
          </van-col>
          <van-col
            span="6"
            class="item flex flex-column"
            @click="showPurchasePopover = true"
          >
            <van-popover v-model:show="showPurchasePopover">
              <template #reference>
                <div class="item2">
                  <van-icon name="gift-o"></van-icon>
                  <div class="fs12 gray-8">采购管理</div>
                </div>
              </template>
              <router-link
                v-for="(item, index) in purchaseList"
                v-permission="item.permission"
                :key="index"
                :to="{ path: item.path }"
              >
                <van-cell
                  :title="item.text"
                  @select="onSelect(item)"
                  :clickable="true"
                ></van-cell>
              </router-link>
            </van-popover>
          </van-col>
          <van-col
            span="6"
            class="item flex flex-column"
            @click="showRetailPopover = true"
          >
            <van-popover v-model:show="showRetailPopover" @select="onSelect">
              <template #reference>
                <div class="item2">
                  <van-icon name="gift-o"></van-icon>
                  <div class="fs12 gray-8">零售管理</div>
                </div>
              </template>
              <router-link
                v-for="(item, index) in retailList"
                v-permission="item.permission"
                :key="index"
                :to="{ path: item.path }"
              >
                <van-cell
                  :title="item.text"
                  :clickable="true"
                  @select="onSelect(item)"
                ></van-cell>
              </router-link>
            </van-popover>
          </van-col>
          <van-col
            span="6"
            class="item flex flex-column"
            @click="showStockPopover = true"
          >
            <van-popover v-model:show="showStockPopover" @select="onSelect">
              <template #reference>
                <div class="item2">
                  <van-icon name="gift-o"></van-icon>
                  <div class="fs12 gray-8">库存管理</div>
                </div>
              </template>
              <router-link
                v-for="(item, index) in stockList"
                v-permission="item.permission"
                :key="index"
                :to="{ path: item.path }"
              >
                <van-cell
                  :title="item.text"
                  :clickable="true"
                  @select="onSelect(item)"
                ></van-cell>
              </router-link>
            </van-popover>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="m15 bg-white">
      <div class="content">
        <div class="title">常用数据</div>
        <van-row type="flex" justify="space-start">
          <van-col
            span="6"
            class="item flex flex-column"
            @click="goTo('/analysis')"
          >
            <div class="item2">
              <van-icon name="gift-o"></van-icon>
              <div class="fs12 gray-8">商品分析</div>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import DateChange from "../../components/dateChange.vue";
import { retail } from "../../service/api/home";
import headerIndex from "../header/index.vue";
export default {
  name: "Home",
  components: { DateChange, headerIndex },
  data() {
    return {
      totalRetailAmount: 0,
      receiveAmount: 0,
      price: 0,
      totalCount: 0,
      paymentTypes: [],
      showStockPopover: false,
      showPurchasePopover: false,
      showRetailPopover: false,
      purchaseList: [
        {
          text: "采购参数设置",
          icon: "",
          path: "/purchase/config",
          permission: "purchase:config:modify",
        },
        {
          text: "采购订单管理",
          icon: "",
          path: "/purchase/order",
          permission: "purchase:order:query",
        },
        {
          text: "采购收货管理",
          icon: "",
          path: "/purchase/receive",
          permission: "purchase:receive:query",
        },
        {
          text: "采购退货管理",
          icon: "",
          path: "/purchase/return",
          permission: "purchase:return:query",
        },
      ],
      retailList: [
        {
          text: "零售参数设置",
          icon: "",
          path: "/retail/config",
          permission: "retail:config:modify",
        },
        {
          text: "零售出库管理",
          icon: "",
          path: "/retail/out",
          permission: "retail:out:query",
        },
        {
          text: "零售退货管理",
          icon: "",
          path: "/retail/return",
          permission: "retail:return:query",
        },
      ],
      stockList: [
        {
          text: "商品库存",
          icon: "",
          path: "/stock/product",
          permission: "stock:product:query",
        },
        {
          text: "库存变动记录",
          icon: "",
          path: "/stock/product/log",
          permission: "stock:product-log:query",
        },
        // { text: "仓库调拨", icon: "", path: "/stock/transfer" ,permission:'stock:sc-transfer:query'},
        {
          text: "商品批次",
          icon: "",
          path: "/stock/lot",
          permission: "stock:lot:query",
        },
      ],
      params: {
        startDate: "",
        endDate: "",
      },
    };
  },
  mounted() {
    this.params.startDate = this.$refs.dateChange.formattedStartDate;
    this.params.endDate = this.$refs.dateChange.formattedEndDate;
    this.getRetail();
  },
  methods: {
    onSelect(action) {
      this.goTo(action.path);
    },
    goTo(target) {
      this.$router.push(target);
    },
    getRetail() {
      retail(this.params).then((res) => {
        for (const key in res.data) {
          if (Object.prototype.hasOwnProperty.call(res.data, key)) {
            this[key] = res.data[key];
          }
        }
      });
      // this.totalRetailAmount = 73.3; // 营业额
      // this.paymentTypes = [
      //   {
      //     payAmount: "73.30", // 付款金额
      //     payTypeId: "1",
      //     payTypeName: "现金", // 支付名称
      //   },
      //   {
      //     payAmount: "73.30", // 付款金额
      //     payTypeId: "1",
      //     payTypeName: "现金", // 支付名称
      //   },
      //   {
      //     payAmount: "73.30", // 付款金额
      //     payTypeId: "1",
      //     payTypeName: "现金", // 支付名称
      //   },
      //   {
      //     payAmount: "73.30", // 付款金额
      //     payTypeId: "1",
      //     payTypeName: "现金", // 支付名称
      //   },
      //   {
      //     payAmount: "73.30", // 付款金额
      //     payTypeId: "1",
      //     payTypeName: "现金", // 支付名称
      //   },
      //   {
      //     payAmount: "73.30", // 付款金额
      //     payTypeId: "1",
      //     payTypeName: "现金", // 支付名称
      //   },
      // ];
    },
    handlerDateChange(startDate, endDate) {
      this.pageIndex = 1;
      this.finished = false;
      this.params = { ...this.params, startDate, endDate };
      this.getRetail();
    },
  },
};
</script>

<style lang="less" scoped>
#home {
  padding-bottom: 50px;
}
header {
  padding: 20px 10px 10px;
}

.content {
  padding: 15px;
  .title {
    padding: 5px 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  .item2 {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #333;
  }
  .van-icon {
    font-size: 20px;
    color: #777;
  }
}
</style>
