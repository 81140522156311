<template>
  <div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="formData.code"
            name="code"
            label="单据号"
          >
          </van-field>
        </van-cell>
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="formData.scId"
            name="scId"
            label="仓库"
          >
          </van-field>
        </van-cell> -->
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="formData.supplierId"
            name="supplierId"
            label="供应商"
          >
          </van-field>
        </van-cell> -->
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="formData.createBy"
            name="createBy"
            label="操作人"
          >
          </van-field>
        </van-cell> -->
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="formData.createStartTime"
            name="createStartTime"
            label="操作日期"
            readonly
            @click="openDatePicker('createStartTime')"
          >
          </van-field>
          <van-field
            autocomplete="off"
            v-model="formData.createEndTime"
            name="createEndTime"
            label="至"
            readonly
            @click="openDatePicker('createEndTime')"
          >
          </van-field>
        </van-cell>
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="formData.approveBy"
            name="approveBy"
            label="审核人"
          >
          </van-field>
        </van-cell> -->
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="formData.approveStartTime"
            name="approveStartTime"
            label="审核日期"
            readonly
            @click="openDatePicker('approveStartTime')"
          >
          </van-field>
          <van-field
           autocomplete="off"
            v-model="formData.approveEndTime"
            name="approveEndTime"
            label="至"
            readonly
            @click="openDatePicker('approveEndTime')"
          >
          </van-field>
        </van-cell> -->
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            v-model="formData.purchaser"
            name="purchaser"
            label="采购员"
          >
          </van-field>
        </van-cell> -->
        <van-cell @click="showStatusPicker = true">
          <van-field
            autocomplete="off"
            v-model="formData.statusText"
            label="状态"
            clearable
          ></van-field>
        </van-cell>
        <van-popup
          close-on-click-overlay
          v-model:show="showStatusPicker"
          round
          position="bottom"
        >
          <van-picker
            show-toolbar
            :columns="statusColumns"
            @cancel="showStatusPicker = false"
            @confirm="onStatusSelectConfirm"
          />
        </van-popup>
        <van-cell>
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            :loading="loading"
            loading-text="查询中..."
          >
            查询
          </van-button>
        </van-cell>
      </van-cell-group>

      <DateTimePicker
        :showPicker="showDatePicker"
        @confirm="onConfirm"
        @cancel="showDatePicker = false"
      ></DateTimePicker>
    </van-form>
    <div style="margin: 16px">
      <div class="action">
        <Van-icon
          v-permission="'purchase:receive:add'"
          name="add-o"
          type="primary"
          @click="$router.push('/purchase/receiveAdd')"
        ></Van-icon>
      </div>
      <CustomTable
        :scrollWidth="800"
        :columns="columns"
        :tableData="tableData"
        :total="totalCount"
        :pageIndex="pageIndex"
        :pageSize="pageSize"
        @pageChange="pageChange"
        @rowClick="rowClick"
      ></CustomTable>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { query } from "../../../service/api/purchase/receive";
import CustomTable from "../../../components/customTable.vue";
import {
  PURCHASE_ORDER_STATUS,
  SETTLE_STATUS,
  getTextByValue,
} from "../../../utils/constants";
import DateTimePicker from "../../../components/dateTimePicker";
import { oneMonthAgoMidnight } from "../../../utils";

export default {
  name: "index",
  components: {
    CustomTable,
    DateTimePicker,
  },
  data() {
    return {
      loading: false,
      showStatusPicker: false,
      action: [
        { name: "审核通过", click: "" },
        { name: "审核拒绝", click: "" },
      ],
      showActions: false,
      columns: [
        {
          field: "code",
          width: 100,
          title: "单据号",
          key: "code",
          fixed: "left",
        },
        // {field:'code', title: "仓库编号", key: "code" },
        { field: "scName", width: 100, title: "仓库名称", key: "scName" },
        // {field:'supplierCode, width:'10%'',width:'15%', title: "供应商编号", key: "supplierCode" },
        {
          field: "supplierName",
          width: 100,
          title: "供应商名称",
          key: "supplierName",
        },
        {
          field: "purchaserName",
          key: "purchaserName",
          title: "采购员",
          width: 100,
        },
        {
          field: "totalAmount",
          width: 60,
          title: "单据总金额",
          key: "totalAmount",
        },
        {
          field: "totalNum",
          width: 60,
          title: "商品数量",
          key: "totalNum",
        },
        {
          field: "totalGiftNum",
          width: 60,
          title: "赠品数量",
          key: "totalGiftNum",
        },
        {
          field: "receiveDate",
          title: "实际到货日期",
          key: "receiveDate",
        },
        {
          field: "createTime",
          title: "操作时间",
          key: "createTime",
        },
        {
          field: "createBy",
          key: "createBy",
          title: "操作人",
          key: "createBy",
          width: 80,
        },

        {
          field: "status",
          width: 60,
          title: "状态",
          fixed: "right",
          key: "status",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const statusClass =
              row.status == "0"
                ? "color1"
                : row.status == "6"
                ? "color2"
                : "color3";
            return (
              <div class={statusClass}>
                {getTextByValue(PURCHASE_ORDER_STATUS, row.status)}
              </div>
            );
          },
        },
        {
          field: "approveTime",
          key: "approveTime",
          title: "审核时间",
          width: 100,
        },
        { field: "approveBy", key: "approveBy", title: "审核人", width: 80 },
        {
          field: "settleStatus",
          title: "结算状态",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const statusClass =
              row.status == "0"
                ? "color1"
                : row.status == "6"
                ? "color2"
                : "color3";
            return (
              <div class={statusClass}>
                {getTextByValue(SETTLE_STATUS, row.status)}
              </div>
            );
          },
        },
        { field: "description", key: "description", title: "备注", width: 200 },
        {
          field: "purchaseOrderCode",
          key: "purchaseOrderCode",
          title: "采购订单号",
          width: 180,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return (
              <div show={text}>
                <div
                  class="blue"
                  on-click={() => this.viewOrderDetail(text)}
                  v-permission="purchase:order:query"
                >
                  {text}
                </div>
                <div v-noPermission="purchase:order:query">{text}</div>
              </div>
            );
          },
        },
      ],
      tableData: [],
      currentDate: "",
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
      totalPage: 1,
      formData: {},
      key: "",
      showDatePicker: false,
    };
  },
  computed: {
    statusColumns() {
      return PURCHASE_ORDER_STATUS;
    },
  },
  created() {
    this.formData.createStartTime = oneMonthAgoMidnight();
    this.formData.createEndTime = moment()
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    this.onSubmit();
  },
  methods: {
    viewOrderDetail(id) {
      // 点击订单号查看订单详情

      this.$router.push({
        path: "/purchase/receiveDetail/" + row.id,
      });
    },
    onStatusSelectConfirm(val) {
      this.formData.status = val.value;
      this.formData.statusText = val.text;
      this.showStatusPicker = false;
    },
    showSettingPopup(e) {
      e.stopPropagation();
      this.showActions = true;
    },
    rowClick({ row, rowIndex, e }) {
      //进入详情页
      this.$router.push({
        path: "/purchase/receiveDetail/" + row.id,
      });
    },
    pageChange(pageOptions) {
      this.pageIndex = pageOptions.pageIndex;
      this.pageSize = pageOptions.pageSize;

      this.getList();
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    openDatePicker(key) {
      this.showDatePicker = true;
      this.key = key;
    },
    onConfirm(value) {
      this.formData[this.key] = value;
      this.showDatePicker = false;
    },
    onSubmit() {
      this.pageIndex = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.formData,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      query(params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.datas;
          this.totalCount = res.data.totalCount;
          this.totalPage = res.data.totalPage;
        })
        .catch(() => {
          this.loading = false;
        });
      //
      // let params = {};
      // for (const key in this.formData) {
      //   if (Object.prototype.hasOwnProperty.call(this.formData, key)) {
      //     const value = this.formData[key] === "是" ? true : false;
      //     params[key] = value;
      //   }
      // }
      // update(params).then((res) => {
      //
      // });
    },
  },
};
</script>
<style lang="less" scoped>
* {
  touch-action: pan-y;
}
.van-cell {
  padding: 5px 2px 2px 15px !important;
}
::v-deep .color1 {
  color: #333;
}
::v-deep .color2 {
  color: red;
}
::v-deep .color3 {
  color: green;
}
</style>
