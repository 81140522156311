import requests from '../request'

const baseUrl = '/basedata/product';
const selectorBaseUrl = '/selector';
const ContentTypeEnum = {
    // json
    JSON: 'application/json;charset=UTF-8',
    // form-data qs
    FORM_URLENCODED: 'application/x-www-form-urlencoded;charset=UTF-8',
    // form-data  upload
    BLOB: 'multipart/form-data;charset=UTF-8',
}
// "/auth/login" 
export const login = (params) => {
    return requests({
        url: '/auth/login',
        method: 'post',
        params
    })

}
export const getCaptchaCode = () => {
    return requests({ url: '/auth/captcha', method: 'get' })

}
export const logout = () => {
    return requests({
        url: '/auth/logout',
        method: 'post',
    })
}
// 仓库
export const reqSc = (params) => {
    return requests({
        url: selectorBaseUrl + '/sc',
        method: 'get',
        params
    })

}
export const reqSupplier = (params) => {
    return requests({
        url: selectorBaseUrl + '/supplier',
        method: 'get',
        params
    })

}
export const reqProduct = (params) => {
    return requests({
        url: selectorBaseUrl + '/product',
        method: 'get',
        params
    })

}
//品牌
export const reqBrand = (params) => {
    return requests({
        url: selectorBaseUrl + '/brand',
        method: 'get',
        params
    })

}
//拿到分类的接口
export const reqWeightCategory = (data) => {
    return requests({
        url: '/basedata/product/category/query',
        method: 'get',
        params: data,
    })
}
// 拿到分类里面的商品
export const reqCategoryItems = (params) => {
    return requests({
        url: '/basedata/product/query',
        method: 'get',
        params,
    })
}
export function loadProduct(ids) {
    return requests(
        {
            url: selectorBaseUrl + '/product/load',
            data: ids,
            method: 'post',
        },
        {
            contentType: ContentTypeEnum.JSON,
        },
    );
}
/**
 * 根据ID查询
 * @param id
 */
export const get = (id) => {
    return requests({
        url: baseUrl,
        method: 'get',
        params: {
            id: id,
        },
    }
    );
}

/**
 * 新增
 * @param data
 */
export const create = (data) => {
    return requests({
        url: baseUrl,
        method: 'post',
        data,
    });
}

/**
 * 修改
 * @param data
 */
export const update = (data) => {
    return requests({
        url: baseUrl,
        method: 'put',
        data,
    });
}
export const queryWarningProductList = (params) => {
    return requests(
        {
            url: '/stock/lot/queryWarningProductList',
            method: 'get',
            params,
        },
    );
}
// system/dic/item/bydic?code=YJPZ
export const queryBydic = () => {
    return requests(
        {
            url: '/system/dic/item/bydic',
            method: 'get',
            params: { code: 'YJPZ' },
        },
    );
}